import { Authenticated, useNavigation } from "@pankod/refine-core";
import { useMemo } from "react";
import RouterProvider from "@pankod/refine-react-router-v6";
import {
  Layout, 
  Header, 
  ControllsContainer, 
  Content, 
  Title, 
  PreviewItem, 
  HeaderContainer,
  WidgetWrapper
} from "./styled";
import { GrnBtn, DarkBtn } from "../../components/default.styled";
import { ConstructorType } from "../constructor/types";

const isDev = process.env.NODE_ENV === "dev";

const PreviewPage = () => {
  const {communityId, type} = 
    RouterProvider.useLocation().state as {
      communityId: string, type: ConstructorType
    };
  const {push} = useNavigation();

  const iframeUrl = useMemo<string>(
    () => isDev ? 
      `http://${communityId}.localhost:1000${type === "widget" ? "/widget" : ""}` : 
      `https://${communityId}.cm.o.dashapps.io${type === "widget" ? "/widget" : ""}`,
    [communityId]
  );

  const onDone = () => {
    push("/constructor", {state: {
      state: "finish",
      type
    }});
  };

  const onBack = () => {
    push("/constructor", {state: {
      state: "constructor",
      type
    }});
  };

  return (
    <Authenticated>
      <Layout>
        <HeaderContainer>
          <Header>
            <Title>View landing page</Title>
            <ControllsContainer>
              <DarkBtn width="inherit" onClick={onBack}>
                Back To Editing
              </DarkBtn>
              <GrnBtn width="inherit" onClick={onDone}>
                Done
              </GrnBtn>
            </ControllsContainer>
          </Header>
        </HeaderContainer>
        <Content>
          {
            type === "landing" ?
              (
                <PreviewItem $isFullSize frameBorder="0" src={iframeUrl} />
              ) : (
                <WidgetWrapper>
                  <PreviewItem frameBorder="0" src={iframeUrl} />
                </WidgetWrapper>
              )
          }
        </Content>
      </Layout>
    </Authenticated>
  );
};


export default PreviewPage