import { createContext, useState } from "react";

export const StoreContext = createContext<any[]>([]);

export const StoreProvider = (props: any) => {
  const activeCommunity = localStorage.getItem("activeCommunity");
  const [activeCommunityId, setActiveCommunity] = activeCommunity
    ? useState<string>(activeCommunity)
    : useState<string>("");

  return (
    <StoreContext.Provider
      value={[activeCommunityId, setActiveCommunity]}
      {...props}
    />
  );
};
