import { useDelete, useList } from "@pankod/refine-core";
import { IInstallation, IMember } from "interfaces";
import { useCallback } from "react";
import KickButtonComponent from "./kick-button-component";

interface KickButtonProps {
  communitySourceId: string;
  type: string;
  userId: string;
  channelId?: string;
  memberName: string;
}

const KickButton: React.FC<KickButtonProps> = ({
  communitySourceId,
  type,
  userId,
  memberName,
  channelId
}) => {
  const { mutate: kickMember} = useDelete<IMember>();

  const {data: installations} = useList<IInstallation>({
    resource: "slack_installations",
    config: {
      filters: [
        {
          field: "teamId",
          operator: "eq",
          value: communitySourceId
        }
      ]
    },
    metaData: {
      fields: ["token"]
    }
  });

  const handleKick = useCallback(() => {
    if (
      type === "slack" && 
      (!installations?.data || !installations?.data[0])
    ) return;

    const token = installations?.data[0]?.token || "";

    kickMember({
      resource: "members",
      id: userId,
      dataProviderName: "custom_rest",
      values: {
        community_id: communitySourceId,
        type,
        user_id: userId, 
        channel_id: channelId,
        token
      },
    });
  }, [installations, communitySourceId, userId, channelId]);

  return (
    <KickButtonComponent 
      memberName={memberName}
      action={handleKick}
      buttonText="Kick"
    />
  )
};

export default KickButton;
