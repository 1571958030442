import { Input } from "@pankod/refine-antd";
import { useCallback, useEffect, useMemo } from "react";
import { successCopyNotification } from "../../components/notification/success-copy-notification";
import { useApiUrl, useCustom, useNavigation } from "@pankod/refine-core";
import { CustomInput, DarkBtn, GrnBtn } from "../../components/default.styled";
import {
  Title,
  StageContent,
  InputContainer,
  CopyButton,
  ButtonsContainer,
  FinishImageContainer,
  TextArea,
} from "./styled";
import { Label } from "../../components/styled";
import { copyIcon } from "../../public/icons";
import doneImage from "../../public/images/done.png";

import type { ConstructorType, StepStage } from "./types";
import axios from "axios";

interface FinishStageProps {
  changeStage: (stage: StepStage) => void;
  communityId?: string;
  type: ConstructorType;
}

const Finish: React.FC<FinishStageProps> = ({
  changeStage,
  communityId = "",
  type,
}) => {
  const { push } = useNavigation();
  const apiUrl = useApiUrl("rest");

  const onboardingLink = useMemo(
    () =>
      `http${process.env.NODE_ENV === "dev" ? "" : "s"}://${communityId}.${
        process.env.NODE_ENV === "dev" ? "localhost:1000" : "cm.o.dashapps.io"
      }${type === "widget" ? "/widget" : ""}`,
    [communityId]
  );

  const { data: htmlSource, isLoading } = useCustom<{ html: string }>({
    dataProviderName: "rest",
    url: `${apiUrl}/templates`,
    method: "get",
    config: {
      query: {
        type,
        communityId,
      },
    },
  });

  const setConstructorStage = useCallback(() => {
    changeStage("constructor");
  }, [changeStage]);

  const onFinish = () => {
    push("/");
  };

  const copyOnboardingLink = useCallback(() => {
    navigator.clipboard.writeText(onboardingLink);
    successCopyNotification("key1");
  }, [onboardingLink]);

  const copyOnboardingSource = useCallback(() => {
    navigator.clipboard.writeText(htmlSource?.data?.html || "");
    successCopyNotification("key2");
  }, [isLoading, htmlSource]);

  return (
    <>
      <FinishImageContainer>
        <img src={doneImage} />
      </FinishImageContainer>
      <Title>
        Your {type === "landing" ? "landing page" : "widget"} is ready!
      </Title>
      <StageContent>
        <InputContainer>
          <Label>{type === "landing" ? "Landing page " : "Widget "}link</Label>
          <Input.Group compact>
            <CustomInput value={onboardingLink} />
            <CopyButton
              icon={<img src={copyIcon} />}
              onClick={copyOnboardingLink}
            />
          </Input.Group>
        </InputContainer>
        <InputContainer>
          <Label>Source</Label>
          <Input.Group compact>
            <TextArea
              rows={4}
              value={isLoading && !htmlSource ? "" : htmlSource?.data.html}
            />
            <CopyButton
              onClick={copyOnboardingSource}
              isTextarea
              icon={<img src={copyIcon} />}
            />
          </Input.Group>
        </InputContainer>
        <ButtonsContainer>
          <DarkBtn width="295px" onClick={setConstructorStage}>
            Back to editing
          </DarkBtn>
          <GrnBtn width="295px" onClick={onFinish}>
            Done
          </GrnBtn>
        </ButtonsContainer>
      </StageContent>
    </>
  );
};

export default Finish;
