import styled, { createGlobalStyle } from "styled-components";
import { AntdLayout, Typography, Input, Divider } from "@pankod/refine-antd";
import {
  GRAY_COLOR,
  DARK_TEXT_COLOR,
  INPUT_COLOR,
  INPUT_PLACEHOLDER_COLOR,
  LIGHT_TEXT_COLOR,
  ORANGE,
  DARK_COLOR,
  GREEN_COLOR,
  DARK_BORDER_COLOR,
} from "../constants/style";
import { DarkBtn, H2Text, P1Text } from "./default.styled";
import { GRAY_FILTER, GREEN_FILTER } from "../constants/filters";
import { backIcon, searchIcon } from "../public/icons";

export const GlobalStyle = createGlobalStyle`
  .upload-modal-wrapper . {}

  .decorated-scroll::-webkit-scrollbar {
    background: ${DARK_COLOR};        /* color of the tracking area */
    width: 14px;
  }
  .decorated-scroll::-webkit-scrollbar-thumb{
    background: ${GREEN_COLOR};
    border-radius: 10px;
    max-height: 10px;
    border: 4px  ${DARK_COLOR} solid;
   
  }
  .custom-notification{
    width: 500px;
    height: 98px;
    background: ${GRAY_COLOR};
    border: 1px solid ${DARK_BORDER_COLOR};
    borderRadius: 20px;
  }
  .custom-notification div.ant-notification-notice-message{
    color: ${GREEN_COLOR};
    font-weight: 600;
    font-size: 16px;
    margin-left: 80px;
  }
  .custom-notification div.ant-notification-notice-description{
    color: ${LIGHT_TEXT_COLOR};
    margin-left: 80px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
      }

    .custom-notification .ant-notification-notice-close-x{
      color: ${LIGHT_TEXT_COLOR};
      &:hover{
        color: white !important;
      }
    }

  .ant-list-item-action .ant-list-item-action-split {
    display: none;
  }
`;

export const DefaultIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const Icon = styled(DefaultIcon)<{ $isActive?: boolean }>`
  margin-right: 10px;
  margin-bottom: 3px;

  ${(props) => (props.$isActive ? GREEN_FILTER : GRAY_FILTER)}
`;

export const SimpleText = styled(P1Text)`
  color: ${DARK_TEXT_COLOR};
  text-align: center;
`;

export const TitleH2 = styled(Typography.Text)`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  padding: 0;
  margin: 0;
  color: ${LIGHT_TEXT_COLOR};
`;

export const Label = styled(P1Text)`
  color: ${LIGHT_TEXT_COLOR};
  margin: 5px 0;
`;

export const CustomInput = styled(Input)<{ color?: string }>`
  background: ${GRAY_COLOR};
  padding: 10px 15px;
  border: none;
  color: ${INPUT_COLOR};
  border-radius: 10px;

  &::placeholder {
    color: ${(props) =>
      props.color ? props.color : `${INPUT_PLACEHOLDER_COLOR}`};
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const ContentWrapper = styled(AntdLayout.Content)`
  padding: 40px;
  display: flex;
  flex-direction: column;
  background: ${DARK_COLOR};
  height: 100vh;
`;

export const SimpleContent = styled(ContentWrapper)`
  flex-grow: 1;
  padding: 0 40px;
  overflow-y: auto;
`;

export const RequestsQuantity = styled.div`
  background: ${ORANGE};
  color: ${DARK_COLOR};
  border-radius; 500px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  border-radius: 500px;
  padding: 0 7px;
  min-width: 25px;
`;

export const SimpleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const HeaderBackButton = styled(DarkBtn)`
  width: 44px;
  height: 44px;
  padding: 0;
`;

export const BackIcon = styled(DefaultIcon).attrs({
  src: backIcon,
})`
  width: 24px;
  height: 24px;
  ${GRAY_FILTER}
  transition: inherit;
  ${HeaderBackButton}:hover & {
    ${GRAY_FILTER}
  }
`;

export const HeaderTitleText = styled(H2Text)`
  color: ${LIGHT_TEXT_COLOR};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DividerContainer = styled(Divider)<{ $isFirst?: boolean }>`
  min-width: 0;
  width: 100%;
  border-bottom: 1px solid ${DARK_BORDER_COLOR};
  ${(props) => (props.$isFirst ? "margin-top: 0;" : "")}
`;

export const SelectIcon = styled.img<{ isButton?: boolean }>`
  width: ${(props) => (props.isButton ? "20px" : "25px")};
  height: ${(props) => (props.isButton ? "20px" : "25px")};
  margin-right: ${(props) => (props.isButton ? "13px" : "10px")};

  ${(props) => (props.isButton ? { GRAY_FILTER } : "")}
`;

export const SearchIcon = styled(DefaultIcon).attrs({
  src: searchIcon,
})`
  ${GRAY_FILTER}
`;

export const SearchInput = styled(CustomInput)`
  width: 100%;

  background: ${DARK_COLOR};
  border: 1px solid ${DARK_BORDER_COLOR};
  cursor: pointer;

  &:hover {
    background: ${GRAY_COLOR};
  }

  &.ant-input-affix-wrapper-focused {
    background: ${GRAY_COLOR};
    border-color: ${GRAY_COLOR};
  }

  &:nth-child(1) input {
    color: ${LIGHT_TEXT_COLOR};
    &::placeholder {
      color: ${DARK_TEXT_COLOR};
    }
  }
`;

export const DarkRedButton = styled(DarkBtn)`
  color: ${ORANGE};
  background: ${GRAY_COLOR};

  &:hover,
  &:focus {
    color: ${LIGHT_TEXT_COLOR};
    background: ${GRAY_COLOR};
  }
  &:active {
    background: ${GRAY_COLOR};
    color: ${ORANGE};
  }
`;
