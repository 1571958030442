import { useModal } from "@pankod/refine-antd";
import { useCreate, useOne, useUpdate } from "@pankod/refine-core";
import { ICommunity } from "interfaces";
import { useCallback, useContext } from "react";
import { StoreContext } from "../../context/store";
import {GrnBtn, H4Text} from "../default.styled";
import Modal from "../modal/modal";
import {
  ModalButton, 
  ModalButtonsContainer,
  ModalMemberName,
  ModalTitle
} from "./styled";

interface ApproveButtonProps {
  requestId: string;
  memberName: string;
  memberEmail?: string;
  communitySourceId: string;
}

const ApproveButton: React.FC<ApproveButtonProps> = ({
  requestId,
  memberName,
  memberEmail,
  communitySourceId
}) => {
  const [activeCommunity, _] = useContext(StoreContext);
  const { mutate: approve } = useUpdate();
  const {mutate: invite} = useCreate();
  const { show, close, modalProps} = useModal();

  const {data: community} = useOne<ICommunity>({
    id: activeCommunity,
    resource: "communities",
    metaData: {
      fields: ["communityData", "landingData", "widgetData"]
    }
  });

  const handleClick = useCallback(() => {
    approve({
      resource: "requests",
      values: {
        status: "approved",
      },
      id: requestId,
    });

    invite({
      dataProviderName: "rest",
      resource: "invites",
      values: {
        email: memberEmail,
        communitySourceId,
        communityName: community?.data.communityData.name,
        communityLogo: community?.data.communityData.communityLogo,
        slackInviteLink: community?.data?.landingData?.inviteLink || community?.data?.widgetData?.inviteLink,
        discordInviteChannel: community?.data?.landingData?.greetingChannelId || community?.data?.widgetData?.greetingChannelId
      }
    })
    close();
  }, [requestId, community]);

  return (
    <>
      <GrnBtn width="auto" onClick={show}>Approve</GrnBtn>
      <Modal
        modalProps={modalProps}
        width="550px"
        height="250px"
        disableCloseIcon
      >
        <ModalTitle>Approve user</ModalTitle>
        <H4Text>
          Are you shure you want to approve{" "}
          <ModalMemberName>{memberName}</ModalMemberName>{" "}?
        </H4Text>
        <ModalButtonsContainer>
          <ModalButton onClick={close}>Cancel</ModalButton>
          <GrnBtn width="auto" onClick={handleClick}>Approve</GrnBtn>
        </ModalButtonsContainer>
      </Modal>
    </>
  );
};

export default ApproveButton;