import { Dropdown } from "@pankod/refine-antd";
import { useApiUrl, useCustom, useNavigation, useOne } from "@pankod/refine-core";
import { useCallback, useContext, useMemo } from "react";
import { StoreContext } from "../../context/store";
import { copyIcon, editIcon, trashIcon } from "../../public/icons";
import {
  MoreIcon,
  MoreMenu,
  MoreMenuItem,
  MoreMenuItemRed,
  MoreMenuItemIcon,
  MoreMenuItemIconRed,
} from "./styled";
import { OnboardingType } from "./types";
import { useModal } from "@pankod/refine-antd";
import Modal from "../../components/modal/modal";
import { H4Text } from "../../components/default.styled";
import {
  ModalTitle,
  ModalButtonsContainer,
  ModalButton,
  ModalButtonLight,
} from "../../components/member-card/styled";
import { ICommunity, ICommunityData } from "interfaces";
import { successCopyNotification } from "../../components/notification/success-copy-notification";

interface ContextMenuProps {
  type: OnboardingType;
  onDelete: (type: OnboardingType) => Promise<void>;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ type, onDelete }) => {
  const [activeCommunityId, setActiveCommunityId] = useContext(StoreContext);
  const { push } = useNavigation();
  const { show, modalProps, close } = useModal();
  const apiUrl = useApiUrl("rest");

  const { data: communityResponse } = useOne<ICommunity>({
    resource: "communities",
    id: activeCommunityId,
    metaData: {
      fields: ["id", "communityData"],
    },
  });

  const { data: htmlSource, isLoading: isLoadingSource } = useCustom<{ html: string }>({
    dataProviderName: "rest",
    url: `${apiUrl}/templates`,
    method: "get",
    config: {
      query: {
        type,
        communityId:  activeCommunityId,
      },
    },
  });

  const community = communityResponse?.data as ICommunity;

  const communityData = useMemo<ICommunityData>(
    () => community?.communityData as ICommunityData,
    [community]
  );

  const openConstructor = useCallback(() => {
    push("constructor", {
      state: {
        state: "constructor",
        type,
      },
    });
  }, [type]);

  const onDeleteOnboarding = useCallback(() => {
    onDelete(type);
    close();
  }, [type, onDelete]);

  const onShow = () => {
    show();
  };

  const copyLink = useCallback(() => {
    successCopyNotification("key1");
    const baseUrl = `http${process.env.NODE_ENV === "dev" ? "" : "s"}://
      ${activeCommunityId}.
      ${
        process.env.NODE_ENV === "dev" ? "localhost:1000/" : "cm.o.dashapps.io"
      }${type === "landing" ? "" : "/widget"}`;

    navigator.clipboard.writeText(baseUrl);
  }, [type, activeCommunityId]);

  const copySource = useCallback(() => {
    successCopyNotification("key2");

    if (!isLoadingSource) {
      navigator.clipboard.writeText(htmlSource?.data?.html || "");
    }
  }, [type, isLoadingSource]);

  const menu = (
    <MoreMenu>
      <MoreMenuItem onClick={openConstructor}>
        <MoreMenuItemIcon src={editIcon} />
        Edit {type === "landing" ? "landing page" : "widget"}
      </MoreMenuItem>
      {type !== "questionnaire" && (
        <>
          <MoreMenuItem onClick={copySource}>
            <MoreMenuItemIcon src={copyIcon} />
            Copy source
          </MoreMenuItem>
          <MoreMenuItem onClick={copyLink}>
            <MoreMenuItemIcon src={copyIcon} />
            Copy link
          </MoreMenuItem>
        </>
      )}
      <MoreMenuItemRed onClick={onShow}>
        <MoreMenuItemIconRed src={trashIcon} />
        Delete
      </MoreMenuItemRed>
      <Modal
        modalProps={modalProps}
        width="550px"
        height="250px"
        disableCloseIcon
      >
        <ModalTitle>
          Delete {type === "landing" ? "landing page" : "widget"}
        </ModalTitle>
        <H4Text>
          Are you sure you want to delete {" " + communityData?.name + " "}
          {type === "landing" ? "landing page" : "widget"}?
        </H4Text>
        <ModalButtonsContainer>
          <ModalButton onClick={close}>Cancel</ModalButton>
          <ModalButtonLight onClick={onDeleteOnboarding}>
            Delete
          </ModalButtonLight>
        </ModalButtonsContainer>
      </Modal>
    </MoreMenu>
  );

  return (
    <Dropdown
      overlayStyle={{ zIndex: "999" }}
      trigger={["click"]}
      overlay={menu}
      placement="topLeft"
      arrow={false}
    >
      <MoreIcon />
    </Dropdown>
  );
};

export default ContextMenu;
