import { createElement, useMemo } from "react";
import { SelectOption } from "interfaces";
import {
  Header,
  LogoText,
  BurgerMenuIcon,
  DividerContainer as Divider,
} from "./styled";
import SelectBox from "./selector";
import { getCommunitiesWithUpdate } from "../getCommunitiesWithUpdate";

interface Props {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiderHeader: React.FC<Props> = ({ collapsed, setCollapsed }: Props) => {
  const { communities, isLoading } = getCommunitiesWithUpdate();

  const selectorOptions = useMemo<SelectOption[]>(() => {
    if (isLoading && !communities) return [];
    return (communities || [])
      .map((it) => ({
        value: it.id,
        label: it.communityData.name,
        type: it.communityData.type,
      }))
      .filter((it) => it && it.label);
  }, [communities, isLoading]);

  return (
    <Header>
      {collapsed ? (
        createElement(BurgerMenuIcon, {
          className: "trigger",
          onClick: () => setCollapsed(!collapsed),
        })
      ) : (
        <>
          <LogoText style={{ display: collapsed ? "none" : "initial" }}>
            logo
          </LogoText>
          <SelectBox options={selectorOptions} isLoading={isLoading} />
          <Divider />
        </>
      )}
    </Header>
  );
};

export default SiderHeader;
