import styled from "styled-components";
import {AntdLayout} from "@pankod/refine-antd";
import { BackIcon, HeaderBackButton, HeaderTitleText } from "./styled";
import { GrnBtn } from "./default.styled";

const Header = styled(AntdLayout.Header)`
  background-color: initial;
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  height: auto;

  &.ant-layout-header {
    height: auto;
  }
`;

const PrimaryButton = styled(GrnBtn)`
  margin-left: auto;
`;

type SimpleHeaderProsp = {
	title: string,
	hasBackButton?: boolean;
  hasPrimaryButton?: boolean;
  primaryButtonText?: string;
	onBackButtonClick?: () => void;
  onPrimaryButtonClick?: () => void;
}

const SimpleHeader: React.FC<SimpleHeaderProsp> = ({
	hasBackButton,
	title,
	onBackButtonClick,
  hasPrimaryButton,
  primaryButtonText,
  onPrimaryButtonClick
}) => {
  return (
		<Header>
			{
				hasBackButton && (
					<HeaderBackButton onClick={onBackButtonClick} >
						<BackIcon />
					</HeaderBackButton>
				)
			}
			<HeaderTitleText>
				{title}
			</HeaderTitleText>
      {
        hasPrimaryButton && (
          <PrimaryButton onClick={onPrimaryButtonClick} width="210px">
            {primaryButtonText}
          </PrimaryButton>
        )
      }
		</Header>
	)
}

export default SimpleHeader;