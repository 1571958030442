import {
  ArrowIcon,
  ConstructorInput,
  ConstructorSelect,
  ConstructorTextarea,
  InputContainer,
} from "../styled";
import { Label } from "../../../components/styled";
import {
  IChannel,
  ICommunity,
  ICommunityData,
  IInstallation,
  IOnboardingForm,
} from "interfaces";
import { useMemo } from "react";
import { Form, useSimpleList } from "@pankod/refine-antd";
import { useList, useOne } from "@pankod/refine-core";
import { GRAY_COLOR } from "../../../constants/style";
import { DropdownContainer } from "../../members/styled";

interface GeneralInfoProps {
  onboarding: IOnboardingForm;
  communityId: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  onboarding,
  communityId,
}) => {
  const { data: communityResponse } = useOne<ICommunity>({
    resource: "communities",
    id: communityId,
    metaData: {
      fields: ["id", "communityData"],
    },
  });

  const community = communityResponse?.data as ICommunity;

  const communityData = useMemo<ICommunityData>(
    () => community?.communityData as ICommunityData,
    [community]
  );

  const { listProps: channelsList } = useSimpleList<IChannel>({
    resource: "channels",
    dataProviderName: "rest",
    permanentFilter: [
      {
        field: "server_id",
        operator: "eq",
        value: communityData?.source,
      },
      {
        field: "type",
        operator: "eq",
        value: communityData.type
      }
    ],
  });

  const channelOptions = useMemo(() => {
    if (!channelsList || !channelsList.dataSource) return [];

    return channelsList?.dataSource.map((it: IChannel) => ({
      value: it.id,
      label: it.name,
    }));
  }, [channelsList, communityData]);

  return (
    <>
      <InputContainer>
        <Label>Heading</Label>
        <Form.Item
          name="heading"
          initialValue={onboarding?.heading || ""}
          rules={[{ required: true, message: "This field is required." }]}
        >
          <ConstructorInput
            autoComplete="off"
            placeholder="e.g. Design community"
          />
        </Form.Item>
      </InputContainer>
      <InputContainer>
        <Label>Description</Label>
        <Form.Item
          name="description"
          rules={[{ required: true, message: "This field is required." }]}
          initialValue={onboarding?.description || ""}
        >
          <ConstructorTextarea
            rows={4}
            placeholder="Shortly describe your community"
          />
        </Form.Item>
      </InputContainer>
      <InputContainer>
        <Label>Button text</Label>
        <Form.Item
          name="buttonText"
          initialValue={onboarding?.buttonText || ""}
          rules={[
            {
              required: true,
              message: "This field is required.",
            },
            {
              max: 20,
              message: "Must be less than 20 characters.",
            },
          ]}
        >
          <ConstructorInput
            autoComplete="off"
            placeholder="e.g. Join our community"
          />
        </Form.Item>
      </InputContainer>
      {communityData?.type === "slack" && (
        <InputContainer>
          <Label>Invite Link</Label>
          <Form.Item
            name="inviteLink"
            initialValue={onboarding?.inviteLink || ""}
            rules={[
              {
                pattern: new RegExp(
                  "^(http://www.|https://www.|http://|https://)+(join.slack.com){1}([/_-]?[a-z0-9])*",
                  "mgi"
                ),
                message: 'Does not match pattern "https://join.slack.com/..."',
              },
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <ConstructorInput
              autoComplete="off"
              placeholder="e.g. Slack invite link"
            />
          </Form.Item>
        </InputContainer>
      )}
      {communityData?.type === "discord" && channelOptions.length && (
        <InputContainer id="area" style={{ position: "relative" }}>
          <Label>Greeting Channel</Label>
          <Form.Item
            name="greetingChannel"
            initialValue={
              onboarding?.greetingChannelId || channelOptions[0]?.value
            }
          >
            <ConstructorSelect
              options={channelOptions}
              defaultValue={channelOptions[0]?.value}
              suffixIcon={<ArrowIcon />}
              dropdownAlign={{ offset: [-15, 4] }}
              dropdownStyle={{
                minWidth: "210px",
                background: `${GRAY_COLOR}`,
                border: "none",
                borderRadius:
                  "border-bottom-right-radius: 10px; border-bottom-left-radius: 10px",
              }}
              bordered={false}
              dropdownRender={(menu) => (
                <DropdownContainer>{menu}</DropdownContainer>
              )}
              getPopupContainer={() =>
                document.getElementById("area") as HTMLElement
              }
            />
          </Form.Item>
        </InputContainer>
      )}
    </>
  );
};

export default GeneralInfo;
