import { useCallback } from "react";
import { DARK_BORDER_COLOR, GRAY_COLOR } from "../../constants/style";
import { SelectWrapper, SelectContainer, DropdownContainer } from "./styled";
import { SelectOption } from "./types";


type CommunitySelectorProps = {
  options: SelectOption[],
  selectedValue: string,
  isLoading?: boolean,
  onChangeChannel: (value: string) => void
};

const CommunitySelector: React.FC<CommunitySelectorProps> = ({
  options,
  selectedValue,
  isLoading,
  onChangeChannel
}) => {
  const onChange = useCallback((value: any) => {
    onChangeChannel(value);
  }, [onChangeChannel]);

  return (
    <SelectWrapper>
      <SelectContainer
        value={selectedValue}        
        onChange={onChange}
        options={options}
        placement="bottomLeft"
        dropdownAlign={{ offset: [-17, 4] }}
        bordered={false}
        dropdownRender={(menu) => (
          <DropdownContainer>
            {menu}
          </DropdownContainer>
        )}
        dropdownStyle={{
          minWidth: "210px",
          background: `${GRAY_COLOR}`,
          border: `1px solid ${DARK_BORDER_COLOR}`,
          borderRadius: "20px",
        }}
      />
    </SelectWrapper>
  )
}

export default CommunitySelector;