import { Identity } from "@ory/client";
import { useApiUrl, useCustom, useGetIdentity } from "@pankod/refine-core";
import { ICommunityData } from "interfaces";
import { useMemo } from "react";

export const getCommunities = () => {
  const apiUrl = useApiUrl("rest");
  const { data: identity } = useGetIdentity<Identity>();

  const identityId = useMemo(() => identity?.id || "no-id", [identity]);

  const { data: communities, isLoading } = useCustom<ICommunityData[]>({
    dataProviderName: "rest",
    url: `${apiUrl}/communities`,
    method: "get",
    config: {
      query: {
        identityId: identityId,
      },
    }
  });

  return {
    isLoading: isLoading,
    communities: communities,
  };
};
