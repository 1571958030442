import { useCallback } from "react";
import { SimpleText } from "../../../components/styled";
import { CheckboxContainer, ConstructorCheckbox, InputContainer } from "../styled";

interface CheckboxWithLabelProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
};

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  value,
  onChange,
  label
}) => {
  const onAction = useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <InputContainer>
      <CheckboxContainer>
        <ConstructorCheckbox checked={value} onChange={onAction} />
        <SimpleText>{label}</SimpleText>
      </CheckboxContainer>
    </InputContainer>
  )
}

export default CheckboxWithLabel;