import React, { useCallback } from "react";
import {
  useRouterContext,
  CanAccess,
  useRefineContext,
  useMenu,
  useLogout,
} from "@pankod/refine-core";
import type { IResourceItem } from "@pankod/refine-core";
import { communitiesIcon, logoutIcon } from "../../public/icons";
import {
  MenuContainer,
  MenuItem,
  MenuIconContainer,
  MenuWrapper as Menu,
  Icon,
} from "./styled";
interface Props {
  collapsed: boolean;
}
const MenuSection: React.FC<Props> = ({ collapsed }: Props) => {
  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { mutate: mutateLogout } = useLogout();

  const onLogout = useCallback(() => {
    mutateLogout();
  }, [mutateLogout]);

  const renderMenuItems = (items: IResourceItem[], selectedKey: string) => {
    return items.map((item: IResourceItem) => {
      const { label, route, name, icon } = item;

      const isSelected = route === selectedKey;

      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <MenuItem key={route} $isSelected={isSelected}>
            <MenuIconContainer>
              <Icon src={icon as string} $isActive={isSelected} />
              <Link to={route}>{label}</Link>
            </MenuIconContainer>
          </MenuItem>
        </CanAccess>
      );
    });
  };

  const dashboard = hasDashboard ? (
    <MenuItem key={"dashboard"} $isSelected={selectedKey === "/"}>
      <MenuIconContainer>
        <Icon src={communitiesIcon} $isActive={selectedKey === "/"} />
        <Link to="/">Dashboard</Link>
      </MenuIconContainer>
    </MenuItem>
  ) : null;

  const items = renderMenuItems(menuItems, selectedKey);

  return (
    <MenuContainer>
      {collapsed ? (
        <Menu>
          <MenuItem
            key="logout"
            onClick={onLogout}
            $isCollapsed
            style={{
              padding: " 0",
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <Icon $isCollapsed src={logoutIcon} style={{ margin: "0" }} />
          </MenuItem>
        </Menu>
      ) : (
        <Menu selectedKeys={[selectedKey]} defaultOpenKeys={defaultOpenKeys}>
          {dashboard}
          {items}
          <MenuItem key="logout" onClick={onLogout}>
            <Icon src={logoutIcon} />
            Logout
          </MenuItem>
        </Menu>
      )}
    </MenuContainer>
  );
};

export default MenuSection;
