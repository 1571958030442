/* colors */
export const ORANGE = "#FD7C53";
export const LIGHT_TEXT_COLOR = "#e9e9e9";
export const DARK_TEXT_COLOR = "#A6A6AE";

export const GREEN_COLOR = "#C6F95A";
export const DARK_GREEN_COLOR = "#32372D";
export const GRAY_COLOR = "#2C2B32";
export const DARK_GRAY_COLOR = "#333333";
export const DARK_COLOR = "#222128";
export const INPUT_COLOR = "#bebebe"; ///???

// Input
export const INPUT_BG_CLR = GRAY_COLOR; //"#2C2B32";
export const INPUT_BG_HOV_CLR = "#39383F";
export const INPUT_PLACEHOLDER_COLOR = "#787884";
export const INPUT_DSBL_CLR = "#e9e9e980"; //INPUT CLR DSBL OPACITY 0.5

// Buttons
//Green
export const GRN_BTN_BG = GREEN_COLOR; //"#C6F95A";
export const GRN_BTN_BG_HOV = "#DBFF8E";
export const GRN_BTN_BG_DSBL = `${GRN_BTN_BG}80`; //opacity 50%
export const GRN_BTN_TXT_CLR = DARK_GRAY_COLOR;
//Orange
export const ORNG_BTN_BG = ORANGE; //"#FD7C53";
export const ORNG_BTN_BG_HOV = "#FF9F81";
export const ORNG_BTN_BG_DSBL = `${ORNG_BTN_BG}80`; //opacity 50%

//Tabs
export const TAB_BG_ACT = DARK_GREEN_COLOR; //"#32372D";

//Border
export const DARK_BORDER_COLOR = "rgba(255, 255, 255, 0.1)";
