import { AutoComplete } from "@pankod/refine-antd";
import React, { useCallback, useMemo } from "react";
import { ICommunityData } from "interfaces";
import _ from "lodash";
import CommunitySelector from "./community-selector";
import {
  DownloadIcon,
  MembersDownloadButton,
  MembersHeaderActionContainer
} from "./styled";
import { SelectOption } from "./types";
import { GRAY_COLOR } from "../../constants/style";
import { SearchIcon, SearchInput } from "../../components/styled";

interface MembersHeaderActionsProps {
  communityData: ICommunityData;
  channelOptions?: SelectOption[];
  selectedChannel: string;
  setSearchValue: (value: string) => void;
  handleDownload: () => void;
  setSelectedChannel: (value: string) => void;
}

const MembersHeaderActions: React.FC<MembersHeaderActionsProps> = ({
  communityData,
  setSearchValue,
  channelOptions = [],
  setSelectedChannel,
  selectedChannel,
  handleDownload
}) => {
  const showSelector = useMemo<boolean>(() => {
    if (!channelOptions || !communityData)
      return false;

    return channelOptions?.length > 1;
  }, [channelOptions, communityData]);

  const changeHandler = (value: string) => {
    setSearchValue(value);
  };
  const debouncedChangeHandler = useCallback(
    _.debounce(changeHandler, 300)
  , []);

  return (
    <MembersHeaderActionContainer>
      {showSelector && (
        <CommunitySelector
          options={channelOptions}
          onChangeChannel={setSelectedChannel}
          selectedValue={selectedChannel}
        />
      )}
      <AutoComplete
        style={{
          width: "300px",
        }}
        filterOption={false}
        onSearch={debouncedChangeHandler}
      >
        <SearchInput
          placeholder="Search"
          suffix={<SearchIcon />}
          bordered={false}
        />
      </AutoComplete>

      <MembersDownloadButton
        color={GRAY_COLOR}
        width="184px"
        icon={<DownloadIcon />}
        onClick={handleDownload}
      >
        Download list
      </MembersDownloadButton>
    </MembersHeaderActionContainer>
  );
};

export default MembersHeaderActions;
