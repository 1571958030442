import { Authenticated, useNavigation } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import SimpleHeader from "../../components/simple-header";
import {
  DividerContainer,
  SimpleContent as Content
} from "../../components/styled";
import ory from "../../data_provider/http_clients/ory-client";
import type { UiNode } from "@ory/client"
import AccountItem from "./account-item";

const Accounts = () => {
  const { push } = useNavigation();
  const [oidcProviders, setOidcProviders] = useState<UiNode[]>();
  const [flowId, setFlowId] = useState<string>("");

  const onBackButtonClick = () => {
    push("/settings");
  };

  const fetchSettings = async () => {
    const {data: {id: flowId}} =  await ory.initializeSelfServiceSettingsFlowForBrowsers();

    const {data: {ui: {nodes}}} = await ory.getSelfServiceSettingsFlow(flowId);

    const oidcProviders = nodes.filter(it => it.group === "oidc");
    setFlowId(flowId);
    setOidcProviders(oidcProviders);
  };


  useEffect(() => {
    fetchSettings().catch(console.error);
  }, []);

  return (
    <Authenticated>
      <SimpleHeader
        title="Accounts"
        hasBackButton
        onBackButtonClick={onBackButtonClick}
      />
      <Content>
        <DividerContainer $isFirst />
        {
          oidcProviders?.map(it => (
            <AccountItem 
              key={it.attributes.node_type} 
              item={it}
              flowId={flowId}
              fetchSettings={fetchSettings}
            />
          ))
        }
      </Content>
    </Authenticated>    
  )
};

export default Accounts;