import styled from "styled-components";
import { DefaultIcon } from "../styled";
import { GRAY_FILTER, GREEN_FILTER } from "../../constants/filters";
import {
  DARK_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  INPUT_PLACEHOLDER_COLOR,
  LIGHT_TEXT_COLOR,
} from "../../constants/style";
import { DarkBtn, GrnBtn } from "../default.styled";

export const TextEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ToolbarContainer = styled.div`
  margin-top: 32px;
  background: inherit;
  border: none !important;
  padding: 0;
`;

export const ToolbarBtn = styled(DarkBtn)`
  width: 24px;
  height: 24px;
  padding: 0px;

  margin-right: 20px;
  padding: 0px;
`;
export const ToolbarIcon = styled(DefaultIcon)`
  width: 24px;
  height: 24px;
  ${GRAY_FILTER}
  transition: inherit;

  ${ToolbarBtn}:hover & {
    ${GREEN_FILTER}
  }
  ${ToolbarBtn}:focus & {
    ${GREEN_FILTER}
  }
  ${ToolbarBtn}.ql-active & {
    ${GREEN_FILTER}
  }
`;

export const Editor = styled.div`
  height: 100%;
  overflow-y: auto;

  font-size: 16px;
  border: none !important;

  background: ${DARK_COLOR};
  border-radius: 10px;
  color: ${LIGHT_TEXT_COLOR} !important;
  & h1,
  h2,
  h3 {
    color: ${LIGHT_TEXT_COLOR} !important;
  }

  & div.ql-tooltip.ql-editing {
    left: 0;
  }

  & div .ql-blank::before {
    color: ${LIGHT_TEXT_COLOR};
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }
`;

export const EditorContainer = styled.div`
  height: 300px;
  margin-top: 23px;

  & .quill {
    border: none;
    overflow-y: auto;
    background: ${GRAY_COLOR};
    border-radius: 10px;
    height: 100%;
  }

  & .quill .ql-container {
    border: none;
  }

  & .quill .ql-container .ql-editor {
    font-size: 16px;
    color: ${LIGHT_TEXT_COLOR};
  }

  & .ql-editor.ql-blank::before {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }
  & .ql-editor::-webkit-scrollbar {
    background: ${DARK_COLOR}; /* color of the tracking area */
    width: 14px;
  }
  & .ql-editor::-webkit-scrollbar-thumb {
    background: ${GREEN_COLOR};
    border-radius: 10px;
    max-height: 10px;
    border: 4px ${DARK_COLOR} solid;
  }
`;
export const ModalButton = styled(DarkBtn)`
  margin-right: 10px;
  width: 98px;
`;
export const ModalButtonLight = styled(GrnBtn)`
  width: 83px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: end;
  margin-top: 30px;
`;
