import { useApiUrl, useCustom, useList, useNavigation, useOne } from "@pankod/refine-core";
import {
  DashboardHeaderContainer,
  DashboardHeaderTitle,
  DashboardHeaderLogoPlacement,
  DashboardHeaderButtonsContainer,
  DashboardHeaderButton,
  ProfileIcon,
  ServerImage,
  DashboardHeaderTitleText,
} from "./styled";
import { ICommunity, ICommunityData, IInstallation, } from "interfaces";
import { useContext, useMemo } from "react";
import { StoreContext } from "../../context/store";

const DashboardHeader: React.FC = () => {
  const apiUrl = useApiUrl("rest");
  const { push } = useNavigation();
  const [activeCommunity, setActiveCommunity] = useContext(StoreContext);

  const { data: communityResponse } = useOne<ICommunity>({
    resource: "communities",
    id: activeCommunity,
    metaData: {
      fields: ["id", "communityData"],
    },
  });

  const {data: installations} = useList<IInstallation>({
    resource: "slack_installations",
    config: {
      filters: [
        {
          field: "teamId",
          operator: "eq",
          value: communityResponse?.data.communityData.source
        }
      ]
    },
    metaData: {
      fields: ["token"]
    }
  })

  const {data: membersCount} = useCustom({
    dataProviderName: "rest",
    url: `${apiUrl}/members/count`,
    method: "get",
    config: {
      query: {
        community_id: communityResponse?.data?.communityData?.source || "",
        type: communityResponse?.data?.communityData?.type,
        token: installations?.data[0]?.token || ""
      },
    },
  });

  const community = communityResponse?.data as ICommunity;

  const communityData = useMemo<ICommunityData>(
    () => community?.communityData as ICommunityData,
    [community]
  );

  const openMembersPage = () => {
    push("/members");
  };

  return (
    <DashboardHeaderContainer>
      <DashboardHeaderTitle>
        <DashboardHeaderLogoPlacement $isLogo={!!communityData?.communityLogo}>
          {communityData?.communityLogo ? (
            <ServerImage src={communityData?.communityLogo} />
          ) : (
            communityData?.name.slice(0, 3)
          )}
        </DashboardHeaderLogoPlacement>
        <DashboardHeaderTitleText>
          {communityData?.name}
        </DashboardHeaderTitleText>
      </DashboardHeaderTitle>
      <DashboardHeaderButtonsContainer>
        <DashboardHeaderButton icon={<ProfileIcon />} onClick={openMembersPage}>
          {`${membersCount?.data || ""} members`}
        </DashboardHeaderButton>
        {/* button will be used in the future */}
        {/* <DashboardHeaderButton icon={<RequestsIcon />}>
          New requests
          <RequestsQuantity>24</RequestsQuantity>
        </DashboardHeaderButton> */}
      </DashboardHeaderButtonsContainer>
    </DashboardHeaderContainer>
  );
};

export default DashboardHeader;
