import styled from "styled-components";
import {
  EmtpyOnboardingContainer,
  HeaderContainer,
} from "../../pages/dashboard/styled";
import {
  HeaderTitleText
} from "../styled";

export const NoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const NoneImageContainer = styled(EmtpyOnboardingContainer)`
  background: inherit;
`;
