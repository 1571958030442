import { useEffect, useRef } from "react";
import _ from "lodash";

type Callback = void | (() => void);
  
export default function useObjectArrayEffect<T>(cb: () => Callback, deps: T[]) {
  const ref = useRef<T[]>([]);

  if (!_.isEqual(deps, ref.current)) {
    ref.current = deps;
  }
  
  useEffect(cb, [ref.current]);
};