import styled from "styled-components";
import { Select } from "@pankod/refine-antd";
import { downloadIcon, searchIcon } from "../../public/icons";
import {
  DARK_TEXT_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  LIGHT_TEXT_COLOR,
} from "../../constants/style";
import {
  DefaultIcon,
  GrnBtn,
  CustomInput,
  H2Text,
} from "../../components/default.styled";
import { GRAY_FILTER } from "../../constants/filters";

export const MembersHeaderContainer = styled.div`
  background-color: initial;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MembersHeaderTitleContainer = styled.div`
  background-color: initial;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MembersHeaderTitleText = styled(H2Text)`
  color: ${LIGHT_TEXT_COLOR};
  margin-left: 20px;
`;
export const MembersHeaderActionContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const MembersDownloadButton = styled(GrnBtn)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  &:active,
  &:focus {
    color: ${GRAY_COLOR} !important;
    background: ${GREEN_COLOR} !important;
  }
`;

export const DownloadIcon = styled(DefaultIcon).attrs({
  src: downloadIcon,
})`
  margin-left: 10px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 250px;
`;

export const SelectContainer = styled(Select)`
  background: ${GRAY_COLOR};
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 10px;
  padding: 0 10px 0 3px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${LIGHT_TEXT_COLOR};

  display: flex;
  align-items: center;

  & .ant-select-selector {
    padding: 0 !important;
    border: none !important;
  }
  & .ant-select-arrow {
    right: 10px;
  }

  & .ant-select-suffix {
    color: ${DARK_TEXT_COLOR};
  }
  &:hover .ant-select-suffix {
    color: ${LIGHT_TEXT_COLOR};
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  div.ant-select-item-option-content:hover {
    color: ${LIGHT_TEXT_COLOR};
    cursor: pointer;
  }

  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: inherit;
  }

  & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: inherit;
  }
`;

export const SelectOption = styled.div`
  background-color: inherit;
  color: ${DARK_TEXT_COLOR};

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${LIGHT_TEXT_COLOR};
  }
`;

export const MemberCardsContainer = styled.div`
  margin-top: 28px;
  overflow-y: auto;
  max-height: 70vh;
  padding: 0;
`;
