import { useModal } from "@pankod/refine-antd";
import { plusIcon } from "../../public/icons";
import ConnectCommunityModal from "../connect-modal";
import {
  AddCommunityIcon,
  AddIconWrapper,
  DropdownButton,
  DropdownDivider,
} from "./styled";

const ConnectButton: React.FC<{}> = () => {
  const { modalProps, show } = useModal();

  return (
    <>
      <DropdownDivider />
      <DropdownButton
        onClick={show}
        icon={
          <AddIconWrapper>
            <AddCommunityIcon src={plusIcon} />
          </AddIconWrapper>
        }
      >
        Connect more
      </DropdownButton>
      <ConnectCommunityModal modalProps={modalProps} />
    </>
  );
};

export default ConnectButton;
