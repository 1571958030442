import styled from "styled-components";
import { Avatar } from "@pankod/refine-antd";
import { AntdList } from "@pankod/refine-antd";
import { mailIcon } from "../../public/icons";
import {
  DARK_BORDER_COLOR,
  DARK_COLOR,
  DARK_TEXT_COLOR,
  LIGHT_TEXT_COLOR,
  ORANGE,
} from "../../constants/style";
import { DarkBtn, H4Text, DefaultIcon, H2Text } from "../default.styled";
import { GRAY_FILTER } from "../../constants/filters";

export const MemberItem = styled(AntdList.Item)`
  border-bottom: 1px solid ${DARK_BORDER_COLOR};
  padding: 15px 0;

  &.ant-list-item {
    border-bottom: 1px solid ${DARK_BORDER_COLOR};
  }

  .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > &.ant-list-item:last-child {
    border-bottom: 1px solid ${DARK_BORDER_COLOR};
  }
`;
export const MemberAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  border-radius: 53px;
`;

export const EmailIcon = styled(DefaultIcon).attrs({ src: mailIcon })`
  ${GRAY_FILTER}
  margin: 0 10px 0 0;
`;

export const EmailContainer = styled.div`
  color: ${DARK_TEXT_COLOR};
  display: flex;
`;
export const MemberName = styled(H4Text)`
  color: ${LIGHT_TEXT_COLOR};
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
`;
export const ModalMemberName = styled(MemberName)`
  overflow: unset;
  width: unset;
`;
export const ModalTitle = styled(H2Text)`
  color: ${LIGHT_TEXT_COLOR};
`;
export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: end;
  margin-top: 30px;
`;
export const ModalButton = styled(DarkBtn)`
  margin-right: 10px;
  width: 98px;
  color: ${LIGHT_TEXT_COLOR};
  &:hover {
    color: ${ORANGE} !important;
  }
`;
export const ModalButtonLight = styled(DarkBtn)`
  width: 83px;
  color: ${DARK_COLOR};
  background: ${ORANGE};
  &:hover {
    color: ${ORANGE} !important;
  }
`;
