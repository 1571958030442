import { Authenticated, useOne } from "@pankod/refine-core";
import { useContext, useMemo, useState } from "react";
import RouterProvider from "@pankod/refine-react-router-v6";
import { ICommunity, IOnboardingForm } from "interfaces";
import { StoreContext } from "../../context/store";
import BeginStage from "./begin-stage";
import ConstructorStage from "./constructor-stage";
import FinishStage from "./finish-stage";
import Header from "./header";
import { Wrapper } from "./styled";

import type { ConstructorType, StepStage } from "./types";

const Constructor: React.FC = () => {
  const state = RouterProvider.useLocation().state as {
    state: StepStage;
    type: ConstructorType;
  };

  const [activeCommunityId, _] = useContext(StoreContext);

  const [stage, setStage] = useState<StepStage>(state?.state || "begin");
  const [constructorType, setConstructorType] = useState<ConstructorType>(
    state?.type || "landing"
  );

  const { data: communityResponse, isLoading } = useOne<ICommunity>({
    resource: "communities",
    id: activeCommunityId,
    metaData: {
      fields: ["id", "communityData", "landingData", "coc", "widgetData", "isPrivate"],
    },
  });

  const community = communityResponse?.data;

  const landingData = useMemo<IOnboardingForm>(
    () => community?.landingData as IOnboardingForm,
    [community, isLoading]
  );
  const widgetData = useMemo<IOnboardingForm>(
    () => community?.widgetData as IOnboardingForm,
    [community, isLoading]
  );
  const cocData = useMemo(() => community?.coc, [community, isLoading]);

  const isUpdate = useMemo(() => {
    if (isLoading) return false;

    if (constructorType === "landing") {
      return Boolean(landingData);
    } else {
      return Boolean(widgetData);
    }
  }, [landingData, widgetData, isLoading, constructorType]);

  const stages = {
    begin: (
      <BeginStage
        changeStage={setStage}
        changeConscructorType={setConstructorType}
      />
    ),
    constructor: (
      <ConstructorStage
        changeStage={setStage}
        communityId={community?.id || ""}
        isUpdate={isUpdate}
        landingData={landingData}
        widgetData={widgetData}
        type={constructorType}
        coc={cocData}
        isPrivateCommunity={community?.isPrivate || false}
      />
    ),
    finish: (
      <FinishStage
        changeStage={setStage}
        communityId={community?.id}
        type={constructorType}
      />
    ),
  };

  return (
    <Authenticated>
      <Header stage={stage} changeStage={setStage} />
      <Wrapper className="decorated-scroll" $centered={stage === "begin"}>
        {stages[stage]}
      </Wrapper>
    </Authenticated>
  );
};
export default Constructor;
