import { useModal } from "@pankod/refine-antd";
import { useCallback } from "react";
import { ORANGE } from "../../../constants/style";
import { H4Text } from "../../default.styled";
import Modal from "../../modal/modal";
import { DarkRedButton } from "../../styled"
import { 
  ModalButton, 
  ModalButtonLight,
  ModalButtonsContainer,
  ModalMemberName,
  ModalTitle
} from "../styled";

type KickButtonComponentProps = {
  buttonText: string;
  memberName: string;
  action: () => void;
}

const KickButtonComponent: React.FC<KickButtonComponentProps> = ({
  buttonText,
  memberName,
  action
}) => {
  const { show, modalProps, close } = useModal();

  const onAction = useCallback(() => {
    action();
    close();
  }, [action])

  return (
    <>
      <DarkRedButton width="auto" color={ORANGE} onClick={show}>
        {buttonText}
      </DarkRedButton>
      <Modal
        modalProps={modalProps}
        width="550px"
        height="250px"
        disableCloseIcon
      >
        <ModalTitle>Kick user</ModalTitle>
        <H4Text>
          Are you sure you want to kick{" "}
          <ModalMemberName>{memberName}</ModalMemberName>{" "}?
        </H4Text>
        <ModalButtonsContainer>
          <ModalButton onClick={close}>Cancel</ModalButton>
          <ModalButtonLight onClick={onAction}>Kick</ModalButtonLight>
        </ModalButtonsContainer>
      </Modal>
    </>
  )
};

export default KickButtonComponent;