import styled from "styled-components";
import { AntdLayout, Button } from "@pankod/refine-antd";
import {
  DARK_BORDER_COLOR,
  DARK_COLOR,
  DARK_TEXT_COLOR,
  GREEN_COLOR,
  TAB_BG_ACT
} from "../../constants/style";

export const Header = styled(AntdLayout.Header)`
  display: flex;
  flex-direction: column;
  background-color: initial;
  padding: 40px;
  gap: 18px;

  height: auto;

  &.ant-layou-header {
    line-height: auto;
  }

  &.ant-layout-header {
    height: auto;
  }
`;

export const HeaderControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const SerchInputConainer = styled.div`
  margin-left: auto;
`;

export const FilterStatusButton = styled(Button)<{$isActive: boolean}>`
  height: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.$isActive ? GREEN_COLOR : DARK_TEXT_COLOR};
  border-radius: 10px;
  padding: 10px 15px;
  background: ${props => props.$isActive ? TAB_BG_ACT : DARK_COLOR};
  border: 1px solid ${DARK_BORDER_COLOR};

  transition: all .25s ease-out;

  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  &.ant-btn:focus, &.ant-btn:active, &.ant-btn:focus:active {
    background: ${TAB_BG_ACT};
    color: ${GREEN_COLOR};
    border: 1px solid ${DARK_BORDER_COLOR};
  }

  &.ant-btn:hover {
    color: ${GREEN_COLOR};
    background: ${DARK_COLOR};
    border: 1px solid ${DARK_BORDER_COLOR};
  }
`;
