import styled from "styled-components";
import { Modal } from "@pankod/refine-antd";
import { DARK_COLOR, GRAY_COLOR } from "../../constants/style";
import { DefaultIcon } from "../styled";
import { closeIcon } from "../../public/icons";
import { GRAY_FILTER } from "../../constants/filters";

export const ModalCloseIcon = styled(DefaultIcon).attrs({ src: closeIcon })`
  width: 14px;
  height: 14px;
  ${GRAY_FILTER}
`;

export const ModalContainer = styled(Modal)<{
  width?: string | number | undefined;
  height?: string | number | undefined;
}>`
  border-radius: 20px;

  & .ant-modal-content {
    ${(props) =>
      props.width
        ? `width: ${props.width} !important`
        : "width: 800px !important"};
    ${(props) =>
      props.height
        ? `height: ${props.height}s !important`
        : "height: 573px !important"};
    background: ${DARK_COLOR};
    border-radius: 20px;
    height: 100%;
    width: 100%;
    margin: 0;
  }
`;
