import { useCallback } from "react";
import {
  Title,
  CardIconContainer,
  CardsContainer,
  CardTitle,
  CardIcon,
  Card
} from "./styled";
import { SimpleText as SubTitle } from "../../components/styled";
import { widgetIcon, worldIcon } from "../../public/icons";
import { ConstructorType, StepStage } from "./types";


interface BeginStageProps {
  changeStage: (step: StepStage) => void,
  changeConscructorType: (type: ConstructorType) => void
}

const Begin: React.FC<BeginStageProps> = ({changeStage, changeConscructorType}) => {
  const onCreateLanding = useCallback(() => {
    changeConscructorType("landing");
    changeStage("constructor");
  }, [changeStage]);

  const onCreateWidget = useCallback(() => {
    changeConscructorType("widget");
    changeStage("constructor");
  }, [changeStage]);

  return (
    <>
      <Title>Create user onboarding</Title>
      <SubTitle>Whould you like to create Widget or Landing page?</SubTitle>
      <CardsContainer>
        <Card onClick={onCreateLanding}>
          <CardIconContainer>
              <CardIcon src={worldIcon}/>
          </CardIconContainer>
          <CardTitle level={3}>Landing page</CardTitle>
          <SubTitle>
            Lorem Ipsum is simply dummy text of the printing and typesetting dummy.
          </SubTitle>
        </Card>
        <Card onClick={onCreateWidget}>
          <CardIconContainer>
              <CardIcon src={widgetIcon}/>
          </CardIconContainer>
          <CardTitle level={3}>Widget</CardTitle>
          <SubTitle>
            Lorem Ipsum is simply dummy text of the printing and typesetting dummy.
          </SubTitle>
        </Card>
      </CardsContainer>
    </>
  )
};

export default Begin;