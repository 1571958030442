import styled, { createGlobalStyle } from "styled-components";
import { Button, Typography, Input } from "@pankod/refine-antd";
import {
  GRAY_COLOR,
  DARK_TEXT_COLOR,
  GREEN_COLOR,
  GRN_BTN_BG,
  GRN_BTN_BG_HOV,
  INPUT_BG_CLR,
  INPUT_BG_HOV_CLR,
  INPUT_DSBL_CLR,
  INPUT_PLACEHOLDER_COLOR,
  LIGHT_TEXT_COLOR,
  ORNG_BTN_BG,
  ORNG_BTN_BG_HOV,
  DARK_COLOR,
} from "../constants/style";

export const GlobalStyle = createGlobalStyle`
  .upload-modal-wrapper . {}
`;
// Img
export const DefaultIcon = styled.img`
  height: 20px;
  width: 20px;
`;

// Text
export const P1Text = styled(Typography.Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
export const P2Text = styled(Typography.Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
export const H1Text = styled(Typography.Text)`
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
`;
export const H2Text = styled(Typography.Text)`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
`;
export const H3Text = styled(Typography.Text)`
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
`;
export const H4Text = styled(Typography.Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

// Input
export const CustomInput = styled(Input)`
  background: ${INPUT_BG_CLR};
  padding: 10px 15px;
  border: none;
  color: ${LIGHT_TEXT_COLOR};
  border-radius: 10px;

  &::placeholder {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }
  &:hover {
    background: ${INPUT_BG_HOV_CLR};
  }
  &:disable {
    color: ${INPUT_DSBL_CLR};
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const CustomTextarea = styled(Input.TextArea)`
  background: ${GRAY_COLOR};
  padding: 10px 15px;
  border: none;
  color: ${LIGHT_TEXT_COLOR};
  border-radius: 10px;

  resize: none;

  overflow-y: auto;
  &::-webkit-scrollbar {
    background: ${DARK_COLOR}; /* color of the tracking area */
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${GREEN_COLOR};
    border-radius: 10px;
    border: 4px ${DARK_COLOR} solid;
  }

  &::placeholder {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }
  &:hover {
    background: ${INPUT_BG_HOV_CLR};
  }
  &:disable {
    color: ${INPUT_DSBL_CLR};
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

//Buttons
const SimpleButton = styled(Button)<{width?: string; color?: string}>`
  padding: 10px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  height: auto;
  line-height: 24px;

  width: ${(props) => (props.width ? props.width : "198px")};

  border-radius: 10px;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
`;

export const GrnBtn = styled(SimpleButton)`
  background: ${GRN_BTN_BG};
  color: ${(props) => (props.color ? props.color : GRAY_COLOR)};
  
  &:hover,
  &:focus {
    background: ${GRN_BTN_BG_HOV};
    border: none;
    color: ${(props) => (props.color ? props.color : GRAY_COLOR)};
  }
  &:active {
    background: ${GRN_BTN_BG};
    border: none;
    color: ${(props) => (props.color ? props.color : GRAY_COLOR)};
  }
  
`;
export const OrngBtn = styled(SimpleButton)`
  background: ${ORNG_BTN_BG};
  color: ${(props) => (props.color ? props.color : GRAY_COLOR)};

  &:hover, &:focus {
    background: ${ORNG_BTN_BG_HOV};
    color: ${(props) => (props.color ? props.color : GRAY_COLOR)};
    border: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const DarkBtn = styled(SimpleButton)`
  color: ${(props) => (props.color ? props.color : LIGHT_TEXT_COLOR)};
  background: ${GRAY_COLOR};

  &:hover,
  &:focus {
    background: ${GRAY_COLOR};
    color: ${(props) => (props.color ? LIGHT_TEXT_COLOR : GREEN_COLOR)};
    border: none;
  }
  &:active {
    background: ${GRAY_COLOR};
    color: ${(props) => (props.color ? props.color : LIGHT_TEXT_COLOR)};
    border: none;
  }
`;

export const BtnWithoutBG = styled(Button)<{ icon?: any; color?: string }>`
  color: ${(props) => (props.color ? props.color : DARK_TEXT_COLOR)};
  background: transparent;
  border: none;
  &:hover {
    color: ${(props) => (props.color ? LIGHT_TEXT_COLOR : GREEN_COLOR)};
    background: transparent;
    border: none;
  }
`;
