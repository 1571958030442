import {LayoutProps} from "@pankod/refine-core";
import {AntdLayout} from "@pankod/refine-antd";
import styled from "styled-components";
import CustomSider from "./sider";
import { GlobalStyle } from "./styled";
import { DARK_COLOR } from "../constants/style";

const Window = styled(AntdLayout)`
  height: 100vh;
`;

const Page = styled(AntdLayout)`
  height: 100%;
  background-color: ${DARK_COLOR};
`;

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Window>
    <GlobalStyle />
    <CustomSider />
    <Page>
      {children}
    </Page>
  </Window>
);

export default Layout;