import {useCallback, useEffect, useState} from "react";
import {useLogin} from "@pankod/refine-core";
import {AntdLayout, Form } from  "@pankod/refine-antd";
import styled from "styled-components";
import { ILoginForm } from "interfaces";
import { DarkBtn } from "../../components/default.styled";
import { DARK_COLOR } from "../../constants/style";
import oryClient from "../../data_provider/http_clients/ory-client";
import { SimpleText } from "../../components/styled";

const LoginLayout = styled(AntdLayout)`
  height: 100vh;
  background-color: ${DARK_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled(Form)`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
`;

const Login: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login } = useLogin<ILoginForm>();

  const onSumbitWithDiscord = useCallback(() => {
    login({provider: "discord"})
  }, [login]);

  const onSubmitWithGoogle = useCallback(() => {
    login({provider: "google"})
  }, [login]);

  const getRegistrationFlow = async () => {
    const {search = ""} = window.location;
    const urlParams = new URLSearchParams(search);
    
    let flowId = urlParams.get("flow") as string;

    if (!flowId || !flowId.length) return;

    const {data: registrationFlow} = await oryClient.getSelfServiceRegistrationFlow(flowId);

    const messages = registrationFlow?.ui?.messages;

    if (messages && messages.length) {
      setError(messages[0].text);
    }
  };

  useEffect(() => {
    getRegistrationFlow();
  }, [])

  return (
    <LoginLayout>
      <LoginForm
        layout="vertical"
        form={form}
        requiredMark={false}
      >
        <DarkBtn width="295px" onClick={onSumbitWithDiscord}>
          Login with discord
        </DarkBtn>
        <DarkBtn width="295px" onClick={onSubmitWithGoogle}>
          Login with Google
        </DarkBtn>
        {
          error.length > 0 && 
          <SimpleText>{error}</SimpleText>
        }
      </LoginForm>
    </LoginLayout>
  );
};

export default Login;