import styled from "styled-components";
import { GREEN_COLOR } from "../../constants/style";
import { DefaultIcon } from "../default.styled";
import { successIcon } from "../../public/icons";

export const SuccessIcon = styled(DefaultIcon).attrs({ src: successIcon })`
  width: 30px;
  height: 30px;
`;
export const SuccessIconWrapper = styled.div`
  width: 58px;
  height: 58px;
  background: ${GREEN_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
