import { useCallback, useMemo } from "react";
import { ICommunitiesMembers, IMember, ISlackMember } from "interfaces";
import MemberCard from "../../components/member-card/member-card"
import { csvmaker, download } from "../../helpers/csv";
import MembersList from "./members-list";
import { MembersContetBaseProps, SelectOption } from "./types";
import ChannelSelectOption from "./channel-select-option";

interface MembersContentProps extends MembersContetBaseProps {
  slackMembers?: ISlackMember[];
};

const MembersContent: React.FC<MembersContentProps> = ({
  communityData,
  setSearchValue,
  selectedChannel,
  setSelectedChannel,
  slackMembers,
  communityMembers
}) => {
  const memberCards = useMemo(() => {
    if (!communityMembers) return [];

    const selectedChannelMembers = communityMembers.find(
      (it) => it.channelId === selectedChannel
    );
  
    const members = communityData.type === "discord" ?
      selectedChannelMembers?.members :
      (selectedChannelMembers?.membersIds || [])
        .map((it) => {
          const slackMember = slackMembers?.find(slackMember => slackMember.slackUserId === it);
          if (!slackMember) return null;
          return {
            id: slackMember?.slackUserId || "",
            avatar: slackMember?.avatar || "",
            name: slackMember?.name || "",
            email: slackMember?.email
          }
        })
        .filter(Boolean) as IMember[];
      
  
    return (members || []).filter(Boolean).map((el) => (
      <MemberCard
        key={el.id}
        item={{
          title: el.name,
          id: el.id,
          image: el.avatar,
          email: el.email
        }}
        actionTypes={["kick", "server-kick"]}
        communitySourceId={communityData.source}
        type={communityData.type}
      />
    ));
  }, [communityMembers, communityData, selectedChannel, slackMembers])

  const channelOptions = useMemo<SelectOption[]>(() => {
    if (!communityMembers) return [];

    return communityMembers?.map((it: ICommunitiesMembers) => ({
      value: it.channelId,
      label: <ChannelSelectOption name={it.channelName} isPublic={it.isPublic} />,
    }));
  }, [communityMembers]);

  const downloadMembersCSV = useCallback(() => {
    const selectedChannelMembers = (communityMembers || []).find(
      (it: ICommunitiesMembers) => it.channelId === selectedChannel
    );

    if (!selectedChannelMembers) return;

    const members = communityData.type === "discord" ?
      selectedChannelMembers?.members :
      (selectedChannelMembers?.membersIds || [])
        .map((it) => {
          const slackMember = slackMembers?.find(slackMember => slackMember.slackUserId === it);
          if (!slackMember) return null;

          return {
            id: slackMember?.slackUserId || "",
            avatar: slackMember?.avatar || "",
            name: slackMember?.name || "",
            email: slackMember?.email
          }
        })
        .filter(Boolean) as IMember[];

    const dataToDownload = (members as IMember[]).map(
      (row: { id: string; name: string }) => ({
        id: row.id,
        name: row.name,
      })
    );

    if (dataToDownload) {
      const csvdata = csvmaker(dataToDownload);
      if (csvdata) download(csvdata, communityData?.name);
    }
  }, [communityMembers, communityData, selectedChannel]);

  return (
    <MembersList
      communityData={communityData}
      selectedChannel={selectedChannel}
      setSearchValue={setSearchValue}
      setSelectedChannel={setSelectedChannel}
      children={memberCards}
      channelOptions={channelOptions}
      downloadMembers={downloadMembersCSV}
    />
  );
};

export default MembersContent;