import { useCallback, useEffect, useState } from "react";
import { useNavigation, useUpdate } from "@pankod/refine-core";
import { IImageData, IOnboardingForm, IFormData } from "interfaces";
import { Divider, Form } from "@pankod/refine-antd";

import {
  Title,
  StageContent as ConstructorForm,
  ButtonsContainer,
} from "../styled";
import { ConstructorType, StepStage } from "../types";
import GeneralInfo from "./general-info";
import UploadControl from "./upload-control";
import COCControl from "./coc-control";
import fileProvider from "../../../data_provider/file-provider";
import { DarkBtn, GrnBtn } from "../../../components/default.styled";
import CheckboxWithLabel from "./checkbox";
import { DARK_BORDER_COLOR } from "../../../constants/style";

interface ConstructorStageProps {
  changeStage: (stage: StepStage) => void;
  communityId: string;
  landingData: IOnboardingForm;
  widgetData: IOnboardingForm;
  isUpdate: boolean;
  type: ConstructorType;
  coc: any;
  isPrivateCommunity: boolean;
}

const Constructor: React.FC<ConstructorStageProps> = ({
  changeStage,
  landingData,
  widgetData,
  communityId = "",
  isUpdate,
  type,
  coc,
  isPrivateCommunity
}) => {
  const { mutate: updateMutation } = useUpdate();
  const { push } = useNavigation();
  const [form] = Form.useForm();
  const [backgroundBlob, setBackgroundBlob] = useState<Blob>();
  const [logoBlob, setLogoBlob] = useState<Blob>();
  const [onboarding, setOnboarding] = useState<IOnboardingForm>(
    type === "landing" ? landingData : widgetData
  );
  const [cocData, setCodeOfConduct] = useState<{[x: string]: string}>(coc);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);

  useEffect(() => {
    if (type === "landing") {
      setOnboarding({ ...landingData });
      return;
    }

    setOnboarding({ ...widgetData });
  }, [communityId, landingData]);

  useEffect(() => {
    setCodeOfConduct(coc);
  }, [communityId, coc]);

  useEffect(() => {
    setIsPrivate(isPrivateCommunity);
  }, [isPrivateCommunity]);

  const onChangeBackgroundSrc = useCallback(
    (value: IImageData | null | undefined) => {
      setOnboarding({
        ...onboarding,
        backgroundSrc: value,
      });
    },
    [onboarding]
  );

  const onChangeLogoSrc = useCallback(
    (value: IImageData | null | undefined) => {
      setOnboarding({
        ...onboarding,
        logoSrc: value,
      });
    },
    [onboarding]
  );

  const saveImage = useCallback(
    async (image: IImageData | null | undefined, file: Blob) => {
      if (image?.url && image?.url.startsWith("blob")) {
        return await fileProvider.uploadOne({
          file: file,
          fileName: image?.name || "",
          folderName: communityId,
        });
      }

      return image?.url || "";
    },
    [communityId]
  );

  const saveOnboarding = async (formData: IFormData) => {
    const { backgroundSrc, logoSrc } = onboarding;

    const backgroundUrl = await saveImage(
      backgroundSrc,
      backgroundBlob as Blob
    );
    const logoUrl = await saveImage(logoSrc, logoBlob as Blob);

    if (
      landingData &&
      landingData.backgroundSrc &&
      landingData.backgroundSrc.url !== backgroundUrl &&
      type === "landing"
    ) {
      await fileProvider.deleteOne({
        filePath: `${communityId}/${landingData?.backgroundSrc?.name || ""}`,
      });
    }

    if (
      landingData &&
      landingData.logoSrc &&
      landingData.logoSrc.url !== logoUrl &&
      type === "landing"
    ) {
      await fileProvider.deleteOne({
        filePath: `${communityId}/${landingData?.logoSrc?.name || ""}`,
      });
    }

    if (
      widgetData &&
      widgetData.logoSrc &&
      widgetData.logoSrc.url !== logoUrl &&
      type === "widget"
    ) {
      await fileProvider.deleteOne({
        filePath: `${communityId}/${widgetData?.logoSrc?.name || ""}`,
      });
    }

    const onboardingData =
      type === "landing"
        ? {
            landingData: {
              ...onboarding,
              ...formData,
              backgroundSrc: {
                ...backgroundSrc,
                url: backgroundUrl,
              },
              logoSrc: {
                ...logoSrc,
                url: logoUrl,
              },
            },
          }
        : {
            widgetData: {
              ...onboarding,
              ...formData,
              logoSrc: {
                ...logoSrc,
                url: logoUrl,
              },
            },
          };

    updateMutation({
      resource: "communities",
      id: communityId,
      values: {
        ...onboardingData,
        coc: cocData,
        isPrivate
      },
    });
  };

  const applyOnboarding = useCallback(async () => {
    try {
      await form.validateFields();
      form.submit();
      changeStage("finish");
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  }, [changeStage, onboarding, cocData, isPrivate]);

  const viewOnboarding = useCallback(async () => {
    try {
      await form.validateFields();
      form.submit();
      push("/preview", { state: { communityId, type } });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  }, [communityId, onboarding, cocData, isPrivate]);

  const onFinish = async (values: { [x: string]: string }) => {
    const formData = {
      heading: values.heading,
      description: values.description,
      buttonText: values.buttonText,
      inviteLink: values.inviteLink,
      greetingChannelId: values.greetingChannel,
    };
    await saveOnboarding(formData);
  };

  return (
    <>
      <Title>Create {type === "landing" ? "landing page" : "widget"}</Title>
      <ConstructorForm>
        <Form
          form={form}
          name="constructor-form"
          style={{ width: "100%" }}
          onFinish={onFinish}
          scrollToFirstError
        >
          <GeneralInfo communityId={communityId} onboarding={onboarding} />
          {type === "landing" && (
            <UploadControl
              updateImageData={onChangeBackgroundSrc}
              imageSrc={onboarding?.backgroundSrc}
              label="Background Image"
              updateBlob={setBackgroundBlob}
            />
          )}
          <UploadControl
            updateImageData={onChangeLogoSrc}
            imageSrc={onboarding?.logoSrc}
            label="Logo Image"
            updateBlob={setLogoBlob}
          />
          <Divider style={{ backgroundColor: DARK_BORDER_COLOR }} />
          <COCControl setCodeOfConduct={setCodeOfConduct} cocData={cocData} />
          <Divider style={{ backgroundColor: DARK_BORDER_COLOR }} />
          <CheckboxWithLabel
            value={isPrivate}
            onChange={setIsPrivate}
            label="Need confirmation to join community"
          />
          {isUpdate ? (
            <ButtonsContainer>
              <DarkBtn width="295px" onClick={viewOnboarding} htmlType="submit">
                {type === "landing" ? "View landing page" : "View widget"}
              </DarkBtn>

              <GrnBtn width="295px" onClick={applyOnboarding} htmlType="submit">
                Save
              </GrnBtn>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <GrnBtn width="100%" onClick={viewOnboarding} htmlType="submit">
                {type === "landing" ? "View landing page" : "View widget"}
              </GrnBtn>
            </ButtonsContainer>
          )}
        </Form>
      </ConstructorForm>
    </>
  );
};

export default Constructor;
