import { Identity } from "@ory/client";
import { useModal } from "@pankod/refine-antd";
import {
  Authenticated,
  useGetIdentity,
  useList,
  useNavigation,
} from "@pankod/refine-core";
import { ICommunity } from "interfaces";
import { useMemo } from "react";
import ConnectCommunityModal from "../../components/connect-modal";
import GetIcon from "../../components/get-icon";
import SettingsItem from "../../components/settings-item";
import SimpleHeader from "../../components/simple-header";
import {
  DividerContainer,
  SimpleContent as Content,
} from "../../components/styled";

const ConnectedCommunities = () => {
  const { push } = useNavigation();
  const { show, modalProps } = useModal();
  const { data: identity } = useGetIdentity<Identity>();
  const identityId = useMemo(() => identity?.id || "no-id", [identity]);
  const { data: connectedCommunitiesResponse, isLoading: isConnectedLoading } =
    useList<ICommunity>({
      resource: "communities",
      config: {
        filters: [
          {
            field: "manager_id",
            operator: "eq",
            value: identityId,
          },
        ],
      },
      metaData: {
        fields: ["id", "communityData", "manager_id"],
      },
    });

  const onBackClick = () => {
    push("/settings");
  };

  const showConnectCommunityModal = () => {
    show();
  };

  return (
    <Authenticated>
      <SimpleHeader
        hasBackButton
        hasPrimaryButton
        title="Connected Communities"
        primaryButtonText="Connect community"
        onBackButtonClick={onBackClick}
        onPrimaryButtonClick={showConnectCommunityModal}
      />
      <Content className="decorated-scroll">
        <DividerContainer $isFirst />
        {connectedCommunitiesResponse?.data.map((it) => (
          <>
            <SettingsItem
              title={it.communityData.name}
              icon={<GetIcon type={it.communityData.type} />}
              button={null}
            />
            <DividerContainer />
          </>
        ))}
      </Content>
      <ConnectCommunityModal modalProps={modalProps} />
    </Authenticated>
  );
};

export default ConnectedCommunities;
