import { AntdLayout } from "@pankod/refine-antd";
import { RefineLayoutSiderProps } from "@pankod/refine-ui-types";
import { useState } from "react";
import styled from "styled-components";
import { DARK_BORDER_COLOR, DARK_COLOR } from "../../constants/style";
import SiderHeader from "./header";
import Menu from "./menu";

const { Sider } = AntdLayout;

const CustomSider = styled(Sider)`
  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background-color: ${DARK_COLOR};
    border-right: 1px solid ${DARK_BORDER_COLOR};
  }
`;

const Sidebar: React.FC<RefineLayoutSiderProps> = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <CustomSider
      width={260}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={(value) => setCollapsed(value)}
    >
      <SiderHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      <Menu collapsed={collapsed} />
    </CustomSider>
  );
};

export default Sidebar;
