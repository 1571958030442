import styled from "styled-components";
import { Select, Divider, Button, Menu } from "@pankod/refine-antd";
import {
  GRAY_FILTER,
  GREEN_FILTER,
  WHITE_FILTER,
} from "../../constants/filters";
import {
  DARK_BORDER_COLOR,
  DARK_GREEN_COLOR,
  DARK_TEXT_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  LIGHT_TEXT_COLOR,
} from "../../constants/style";
import { DefaultIcon } from "../default.styled";
import { MenuOutlined } from "@ant-design/icons";

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 180px;
  width: 100%;
`;

export const SelectContainer = styled(Select)`
  background: none;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${LIGHT_TEXT_COLOR};
  width: 100%;

  & .ant-select-selector {
    padding: 0 !important;
  }
  & .ant-select-arrow {
    right: 0px;
  }

  & .ant-select-suffix {
    color: ${DARK_TEXT_COLOR};
  }
  &:hover .ant-select-suffix {
    color: ${LIGHT_TEXT_COLOR};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: ${GREEN_COLOR};
  width: 180px;
  text-align: left;
  margin: 30px 40px 30px 40px;
  text-transform: uppercase;
`;
export const DropdownButton = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${GREEN_COLOR};
  display: flex;
  background: none;
  border: none;
  width: 100%;
  padding-left: 15px;
  &:hover {
    background: none;
    border: none;
    color: ${LIGHT_TEXT_COLOR};
  }
  &:active,
  &:focus {
    background: none;
    border: none;
    color: ${GREEN_COLOR};
  }
`;

export const AddCommunityIcon = styled.img`
  width: 20px;
  height: 20px;

  transition: inherit;
  ${GREEN_FILTER}
  ${DropdownButton}:hover & {
    ${WHITE_FILTER}
  }
`;

export const AddIconWrapper = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DividerContainer = styled(Divider)`
  min-width: 0;
  width: 210px;
  border-bottom: 1px solid ${DARK_BORDER_COLOR};
`;

export const MenuItem = styled(Menu.Item)<{
  $isSelected?: boolean;
  $isCollapsed?: boolean;
}>`
  height: 54px !important;
  max-width: 80%;
  margin: 5px 25px;
  border-radius: 10px;
  transition: inherit;
  ${(props) =>
    props.$isSelected
      ? `background-color: ${DARK_GREEN_COLOR} !important;`
      : ""}
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  user-select: none;
  color: ${(props) => (props.$isSelected ? GREEN_COLOR : DARK_TEXT_COLOR)};

  &:last-child {
    margin-top: auto;
    margin-bottom: 42px;
  }

  & a {
    color: ${(props) => (props.$isSelected ? GREEN_COLOR : DARK_TEXT_COLOR)};
  }

  & a:hover {
    color: ${(props) => (props.$isSelected ? GREEN_COLOR : LIGHT_TEXT_COLOR)};
  }
  &.ant-menu-item:hover {
    ${(props) =>
      props.$isSelected
        ? `color: ${DARK_GREEN_COLOR} !important; color: ${GREEN_COLOR};`
        : `background-color: ${GRAY_COLOR} !important; color: ${LIGHT_TEXT_COLOR};`}
  }
  &.ant-menu-item:hover img {
    ${(props) => (props.$isSelected ? `${GREEN_FILTER}` : `${WHITE_FILTER}`)}
  }

  &:last-child:hover {
    color: ${GREEN_COLOR} !important;
    background-color: ${(props) =>
      props.$isCollapsed ? "transparent !important" : "inherit"};
  }

  &:last-child:hover img {
    ${GREEN_FILTER}
  }
`;

export const MenuContainer = styled.div`
  width: 260px;
  flex-grow: 1;
`;

export const MenuWrapper = styled(Menu)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: inherit;
`;

export const Icon = styled(DefaultIcon)<{
  $isActive?: boolean;
  $isCollapsed?: boolean;
}>`
  margin-right: ${(props) => (props.$isCollapsed ? "0px" : "10px")};
  margin-bottom: ${(props) => (props.$isCollapsed ? "0px" : "3px")};
  transition: inherit;

  ${(props) => (props.$isActive ? GREEN_FILTER : GRAY_FILTER)}
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  div.ant-select-item-option-content:hover {
    color: ${LIGHT_TEXT_COLOR};
    cursor: pointer;
  }
`;

export const DropdownDivider = styled(Divider)`
  min-width: 0;
  width: 180px;
  margin: 8px 0;
  border-bottom: 1px solid ${DARK_BORDER_COLOR};
  align-self: center;
`;

export const SelectOptionStyles = {
  background: "inherit",
  color: DARK_TEXT_COLOR,

  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  padding: "10px 15px",
};

export const BurgerMenuIcon = styled(MenuOutlined)`
  font-size: 150%;
  color: ${DARK_TEXT_COLOR};
  margin-top: 40px;
  transition: inherit;
  &:hover {
    color: ${GREEN_COLOR};
  }
`;

export const ConnectModalContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;
