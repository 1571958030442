export const download = (data: string, communityName: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${communityName}MembersList.csv`);
  a.click();
};

export const csvmaker = (data: { id: string; name: string }[]) => {
  if (data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    for (let key in data) {
      const values = Object.values(data[key]).join(",");
      csvRows.push(values);
    }
    return csvRows.join("\n");
  }
};