import _ from "lodash";
import { AutoComplete } from "@pankod/refine-antd";
import { Nullable, RequestStatus } from "interfaces";
import { Dispatch, SetStateAction, FC, useCallback} from "react";
import { HeaderTitleText, SearchIcon, SearchInput } from "../../components/styled";
import FilterControl from "./filter-control";
import { Header, HeaderControlsContainer, SerchInputConainer } from "./styled";
import { CrudFilters } from "@pankod/refine-core";

interface RequestsHeaderProps {
  statusFilter: Nullable<RequestStatus>;
  setFilters: ((filters: CrudFilters) => void) & ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
  setStatusFilter: Dispatch<SetStateAction<RequestStatus>>;
  newRequestsNumber: number;
}

const RequestsHeader: FC<RequestsHeaderProps> = ({
  statusFilter,
  setFilters,
  setStatusFilter,
  newRequestsNumber
}) => {
  const changeHandler = (value: string) => {
    setFilters([
      {
        field: "email",
        operator: "contains",
        value
      }
    ]);
  };

  const debouncedChangeHandler = useCallback(
    _.debounce(changeHandler, 300)
  , []);

  return (
    <Header>
      <HeaderTitleText>All Requests</HeaderTitleText>
      <HeaderControlsContainer>
        <FilterControl
          title="New requests"
          value="none"
          onAction={setStatusFilter}
          isActive={statusFilter !== "approved" && statusFilter !== "rejected"}
          requestsQuantity={newRequestsNumber}
        />
        <FilterControl
          title="Approved"
          value="approved"
          onAction={setStatusFilter}
          isActive={statusFilter === "approved"}
        />
        <FilterControl
          title="Rejected"
          value="rejected"
          onAction={setStatusFilter}
          isActive={statusFilter === "rejected"}
        />
        <SerchInputConainer>
          <AutoComplete
            style={{
              width: "300px",
            }}
            filterOption={false}
            onSearch={debouncedChangeHandler}
          >
            <SearchInput
              placeholder="Search"
              suffix={<SearchIcon />}
              bordered={false}
            />
          </AutoComplete>
        </SerchInputConainer>
      </HeaderControlsContainer>
    </Header>
  )
};

export default RequestsHeader;