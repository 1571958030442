import { useList, useOne } from "@pankod/refine-core";
import { ICommunitiesMembers, ICommunity, IInstallation } from "interfaces";
import { useContext, useState } from "react";
import { ContentWrapper } from "../../components/styled";
import { getCommunities } from "../../components/getCommunities";
import NoneCommunities from "../../components/none-communities";
import { StoreContext } from "../../context/store";
import MembersContent from "./members-content";
import SlackMembersHoc from "./slack-member-hoc";
import { useSimpleList } from "@pankod/refine-antd";
import useObjectArrayEffect from "../../hooks/useObjectArrayEffect";

const Members: React.FC = () => {
  const { isLoading, communities } = getCommunities();
  const [activeCommunity, setActiveCommunity] = useContext(StoreContext);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  
  const { data: communityResponse } = useOne<ICommunity>({
    resource: "communities",
    id: activeCommunity,
    metaData: {
      fields: ["id", "communityData"],
    },
  });

  const communityData = communityResponse?.data.communityData;

  const {data: slackAppData} = useList<IInstallation>({
    resource: "slack_installations",
    config: {
      filters: [
        {
          field: "teamId",
          operator: "eq",
          value: communityData?.source
        }
      ]
    },
    metaData: {
      fields: ["token"]
    }
  });

  const { listProps: communityMembers } = useSimpleList<ICommunitiesMembers>({
    resource: "members",
    dataProviderName: "custom_rest",
    permanentFilter: [
      {
        field: "type",
        operator: "eq",
        value: communityData?.type,
      },
      {
        field: "community_id",
        operator: "eq",
        value: communityData?.source,
      },
      {
        field: "search_value",
        operator: "eq",
        value: searchValue
      },
      {
        field: "token",
        operator: "eq",
        value: slackAppData?.data[0]?.token || ""
      }
    ],
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    hasPagination: false,
  });

  useObjectArrayEffect(() => {
    if (!communityMembers?.dataSource || selectedChannel.length) return;

    setSelectedChannel(communityMembers?.dataSource[0].channelId);
  }, [...communityMembers.dataSource || []])

  const content = () => {
    switch (communityData?.type) {
      case "slack":
        return (
          <SlackMembersHoc
            searchValue={searchValue}
            communityData={communityData}
            setSearchValue={setSearchValue}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            communityMembers={communityMembers?.dataSource}
          />
        )
      case "discord":
        return (
          <MembersContent
            communityData={communityData}
            setSearchValue={setSearchValue}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            communityMembers={communityMembers.dataSource}
          />
        )
      default:
        return null;
    }
  }

  return (
    <>
      {!isLoading && !communities ? (
        <NoneCommunities />
      ) : (
        <ContentWrapper>
          {content()}
        </ContentWrapper>
      )}
    </>
  );
};

export default Members;
