import {
  Identity,
  UiNodeInputAttributes,
  SubmitSelfServiceSettingsFlowBody
} from "@ory/client";
import { AxiosError } from "axios";
import ory from "./data_provider/http_clients/ory-client";
import { ILoginForm } from "interfaces";

const authProvider = {
  login: async (values: ILoginForm) => {
    try {
      const {data: {id: flowId}} = await ory.initializeSelfServiceLoginFlowForBrowsers(true);

      const {data: {ui: {nodes = []}}} = await ory.getSelfServiceLoginFlow(flowId);

      const csrfTokenAttrs  = nodes[nodes.length - 1].attributes as UiNodeInputAttributes;

      await ory.submitSelfServiceLoginFlow(
        flowId,
        {
          method: "oidc",
          provider: values.provider,
          csrf_token: csrfTokenAttrs.value || ""
        }
      );

      return Promise.resolve();
    } catch (error: any) {
      const {response} = error as AxiosError<{error: any, redirect_browser_to?: string}>;

      const errorId = response?.data.error?.id;

      switch(errorId) {
        case "browser_location_change_required":
          window.location.href = response?.data.redirect_browser_to || ""
          return;
      }
    }
  },
  updatePassword: async ({
    data, 
    flowId
  }: {
    data:  SubmitSelfServiceSettingsFlowBody,
    flowId: string
  }) => {
    try {
      await ory.submitSelfServiceSettingsFlow(flowId, data);
      return Promise.resolve();
    } catch(error: any) {
      const {response} = error as AxiosError<{error: any, redirect_browser_to?: string}>;

      const errorId = response?.data.error?.id;

      switch(errorId) {
        case "browser_location_change_required":
          window.location.href = response?.data.redirect_browser_to || ""
          return;
      }
    }
  }, 
  logout: async () => {
    try {
      const {data: logoutFlow} =
       await ory.createSelfServiceLogoutFlowUrlForBrowsers();

      await ory.submitSelfServiceLogoutFlow(logoutFlow.logout_token);
      Promise.resolve();
    }
    catch {
      Promise.reject();
    }
  },
  checkAuth: async () => {
    const {data: session } = await ory.toSession();
    return session.id ? Promise.resolve() : Promise.reject();
  },
  checkError: (error: any) => {
    if (error.status === 401) {
          return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async (): Promise<Identity> => {
    const {data: session } = await ory.toSession();
    return session.identity;
  },
}

export default authProvider;