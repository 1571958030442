import { useCallback, useMemo } from "react";
import { useModal, RcFile } from "@pankod/refine-antd";
import { IImageData } from "interfaces";

import {
  UploadButton,
  UploadIcon,
  InputContainer,
  UploadControlsContainer,
  UploadSubtitle,
  UploadTitle,
  PreviewContainer,
  PreviewImage,
  PreviewInfoContainer,
  PreviewDeleteIcon,
} from "../styled";
import { Label } from "../../../components/styled";
import UploadWindow from "./upload-modal";

interface UploadControlProps {
  imageSrc: IImageData | undefined | null;
  label: string;
  updateImageData: (value: IImageData | null | undefined) => void;
  updateBlob: (value: Blob) => void;
};

const UploadControl: React.FC<UploadControlProps> = ({
  label,
  imageSrc,
  updateImageData,
  updateBlob,
}) => {
  const { show, modalProps, close } = useModal();
  const image = useMemo<IImageData>(
    () => imageSrc || ({} as IImageData),
    [imageSrc]
  );

  const displayFilesize = useMemo(() => {
    if (!image) return 0;
    return Math.round(Number(image?.size) / 1024);
  }, [image]);

  const removeImage = useCallback(() => {
    updateImageData(null);
  }, [updateImageData]);

  const beforUploadHandler = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const blob = new Blob([e.target.result], { type: file.type });
      updateBlob(blob);
      const loadUlr = URL.createObjectURL(blob);
      updateImageData({
        url: loadUlr,
        size: file.size,
        name: file.name,
      });
      close();
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  return (
    <>
      <InputContainer>
        <UploadControlsContainer>
          <Label>{label}</Label>
          <UploadButton width="133px" onClick={show} icon={<UploadIcon />}>
            Upload
          </UploadButton>
        </UploadControlsContainer>
        {image.url && (
          <PreviewContainer>
            <PreviewImage src={image.url} />
            <PreviewInfoContainer>
              <UploadTitle>{image.name}</UploadTitle>
              <UploadSubtitle
                $isPreview
              >{`${displayFilesize} kb`}</UploadSubtitle>
            </PreviewInfoContainer>
            <PreviewDeleteIcon onClick={removeImage} />
          </PreviewContainer>
        )}
      </InputContainer>
      <UploadWindow
        modalProps={modalProps}
        uploadHandler={beforUploadHandler}
      />
    </>
  );
};

export default UploadControl;
