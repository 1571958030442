import styled from "styled-components";
import { AntdLayout, Menu } from "@pankod/refine-antd";
import {
  attentionIcon,
  moreIcon,
  profileIcon,
  requestsIcon,
} from "../../public/icons";
import {
  DARK_BORDER_COLOR,
  DARK_COLOR,
  DARK_TEXT_COLOR,
  GRAY_COLOR,
  INPUT_BG_HOV_CLR,
  LIGHT_TEXT_COLOR,
  ORANGE,
} from "../../constants/style";
import {
  BtnWithoutBG,
  DefaultIcon,
  H3Text,
} from "../../components/default.styled";
import {
  GRAY_FILTER,
  GREEN_FILTER,
  ORANGE_FILTER,
} from "../../constants/filters";
import { HeaderTitleText } from "../../components/styled";

export const HeaderContainer = styled(AntdLayout.Header)`
  background-color: initial;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const DashboardHeaderContainer = styled(HeaderContainer)`
  padding: 40px;
  height: auto;

  & .ant-layout-header {
    height: auto;
  }
`;
export const HeaderControls = styled.div`
  display: flex;
`;

export const Control = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${DARK_TEXT_COLOR};
`;

export const Content = styled(AntdLayout.Content)`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
`;

export const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;

  //Will be removed
  margin-top: auto;
`;

export const OnboardingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

export const OnboardingText = styled(H3Text)`
  letter-spacing: 0em;
  text-align: left;
  color: ${LIGHT_TEXT_COLOR};
`;
export const OnboardingItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  //will be removed
  min-height: 240px;
`;

export const EmtpyOnboardingContainer = styled(OnboardingItemsContainer)`
  background-color: ${GRAY_COLOR};
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export const OnboardingItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${GRAY_COLOR};
  border-radius: 10px;
  margin: 5px 0;
  padding: 10px;
  height: 54px;
  &:hover {
    background: ${INPUT_BG_HOV_CLR};
    cursor: pointer;
  }
`;

export const EmptyImageText = styled.div`
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${DARK_TEXT_COLOR};
`;

export const CaptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OnboardingIcon = styled.img<{ isActive?: boolean }>`
  height: 22px;
  width: 22px;
  margin: 0 10px;
  margin-bottom: 3px;
  ${GREEN_FILTER}
`;

export const MoreIcon = styled(OnboardingIcon).attrs({ src: moreIcon })`
  ${GRAY_FILTER}
  cursor: pointer;
  &:hover {
    ${GREEN_FILTER}
  }
`;

export const AttentionIcon = styled(OnboardingIcon).attrs({
  src: attentionIcon,
})`
  ${ORANGE_FILTER}
`;

export const OnboardingControls = styled.div`
  display: flex;
`;

export const PaymentAttention = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DashboardHeaderTitle = styled.div`
  display: flex;
  max-width: 400px;
  width: 100%;
  gap: 20px;
`;

export const DashboardHeaderLogoPlacement = styled.div<{ $isLogo?: boolean }>`
  width: 44px;
  height: 44px;
  background: ${(props) =>
    props.$isLogo
      ? "transparent"
      : "linear-gradient(135deg, #fb5d79 0%, #fb8a4b 100%)"};
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: ${GRAY_COLOR};
  overflow: hidden;
`;

export const DashboardHeaderButtonsContainer = styled.div`
  display: flex;
`;

export const DashboardHeaderButton = styled(BtnWithoutBG)`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
export const RequestsIcon = styled(DefaultIcon).attrs({ src: requestsIcon })`
  ${GRAY_FILTER}
  transition: inherit;
  margin: 0 10px 0 0;
  ${DashboardHeaderButton}:hover & {
    ${GREEN_FILTER}
  }
`;
export const ProfileIcon = styled(DefaultIcon).attrs({ src: profileIcon })`
  ${GRAY_FILTER}
  transition: inherit;
  margin: 0 10px 0 0;
  ${DashboardHeaderButton}:hover & {
    ${GREEN_FILTER}
  }
`;

export const ServerImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const MoreMenu = styled(Menu)`
  background-color: ${GRAY_COLOR};
  border: 1px solid ${DARK_BORDER_COLOR};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 215px;
  padding: 10px;
`;

export const MoreMenuItem = styled.div`
  color: ${DARK_TEXT_COLOR};
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background: ${DARK_COLOR};
  }
`;

export const MoreMenuItemRed = styled(MoreMenuItem)`
  color: ${ORANGE};
`;

export const MoreMenuItemIcon = styled(DefaultIcon)`
  ${GRAY_FILTER};
  margin-right: 10px;
  margin-bottom: 5px;
`;

export const MoreMenuItemIconRed = styled(MoreMenuItemIcon)`
  ${ORANGE_FILTER}
`;

export const DashboardHeaderTitleText = styled(HeaderTitleText)`
  max-width: 300px;
`;
