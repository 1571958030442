import { Authenticated, useNavigation } from "@pankod/refine-core";
import SimpleHeader from "../../components/simple-header";
import { accountIcon, communitiesIcon } from "../../public/icons";
import SettingsItem from "../../components/settings-item";
import { ClickableArea, ForwardButton, SettingsIcon as Icon } from "./styled";
import {
  DividerContainer,
  SimpleContent as Content,
} from "../../components/styled";

const Settings = () => {
  const { push } = useNavigation();

  const toConnectedCommunities = () => {
    push("/connected-communities");
  };

  const toAccounts = () => {
    push("/accounts");
  };

  return (
    <Authenticated>
      <SimpleHeader title="Settings" />
      <Content>
        <DividerContainer $isFirst />
        <ClickableArea onClick={toConnectedCommunities}>
          <SettingsItem
            title="Connected Communities"
            icon={<Icon src={communitiesIcon} />}
            button={<ForwardButton />}
          />
        </ClickableArea>

        <DividerContainer />
        <ClickableArea onClick={toAccounts}>
          <SettingsItem
            title="Accounts"
            icon={<Icon src={accountIcon} />}
            button={<ForwardButton />}
          />
        </ClickableArea>
        <DividerContainer />
      </Content>
    </Authenticated>
  );
};

export default Settings;
