import { useCallback, useEffect, useState } from "react";
import { useModal } from "@pankod/refine-antd";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { successCopyNotification } from "../../../components/notification/success-copy-notification";
import ReactQuill from "react-quill";
import {
  InputContainer,
  AddButton,
  AddIcon,
  EditIcon,
  CopyIcon,
  PreviewDeleteIcon,
  CocContainer,
  CocIcon,
  CocText,
} from "../styled";
import { SimpleContainer, TitleH2 } from "../../../components/styled";
import Modal from "../../../components/modal/modal";
import TextEditor from "../../../components/text-editor/text-editor";
import { GREEN_COLOR } from "../../../constants/style";

interface COCControlProps {
  setCodeOfConduct?: any;
  cocData?: any;
}

const COCControl: React.FC<COCControlProps> = ({
  setCodeOfConduct,
  cocData,
}) => {
  const { show, modalProps, close } = useModal();
  const [deltaValue, setDeltaValue] = useState(cocData || {});

  const handleChange: (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => void = (content, delta, source, editor) => {
    setDeltaValue(editor.getContents());
  };

  const onDone = useCallback(() => {
    setCodeOfConduct(deltaValue);
    close();
  }, [deltaValue, setCodeOfConduct]);

  const onDelete = useCallback(() => {
    setCodeOfConduct(null);
  }, [setCodeOfConduct]);

  useEffect(() => {
    setDeltaValue(cocData);
  }, [cocData]);

  const onCopy = () => {
    successCopyNotification("ke1");
    const converter = new QuillDeltaToHtmlConverter(cocData?.ops || null, {});
    const html = converter.convert();

    navigator.clipboard.writeText(html);
  };

  const onShow = () => {
    setDeltaValue(cocData);
    show();
  };

  return (
    <>
      {cocData && cocData.ops[0].insert !== "\n" ? (
        <CocContainer>
          <SimpleContainer>
            <CocIcon />
            <CocText>Code of conduct</CocText>
          </SimpleContainer>
          <SimpleContainer>
            <EditIcon onClick={onShow} />
            <CopyIcon onClick={onCopy} />
            <PreviewDeleteIcon onClick={onDelete} />
          </SimpleContainer>
        </CocContainer>
      ) : (
        <InputContainer>
          <AddButton color={GREEN_COLOR} onClick={onShow} icon={<AddIcon />}>
            Add Code of conduct
          </AddButton>
        </InputContainer>
      )}
      <Modal modalProps={modalProps}>
        <TitleH2>Add code of conduct</TitleH2>
        <TextEditor
          onClose={close}
          onChange={handleChange}
          onDone={onDone}
          value={deltaValue}
        />
      </Modal>
    </>
  );
};

export default COCControl;
