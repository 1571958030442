import { UiNode, UiNodeInputAttributes} from "@ory/client";
import { useUpdatePassword } from "@pankod/refine-core";
import { useCallback, useMemo } from "react";
import { GrnBtn } from "../../components/default.styled";
import GetIcon from "../../components/get-icon";
import SettingsItem from "../../components/settings-item";
import { DarkRedButton, DividerContainer } from "../../components/styled";

type AccountItemProps = {
  item: UiNode,
  flowId: string,
  fetchSettings: () => Promise<void>
};

const AccountItem: React.FC<AccountItemProps> = ({item, flowId, fetchSettings}) => {
  const {mutate: updateProvider} = useUpdatePassword();
  const attributes = useMemo<UiNodeInputAttributes>(() => item.attributes as UiNodeInputAttributes, [item]);
  const title = useMemo<string>(
    () => attributes.value.charAt(0).toUpperCase() + attributes.value.slice(1), 
    [attributes]
  );

  const onAction = useCallback(() => {
    if (flowId.length) {
      if (attributes.name === "link") {
        updateProvider({
          data: {
            method: "oidc",
            flow: flowId,
            link: attributes.value
          }, 
          flowId
        });
      }

      if (attributes.name === "unlink") {
        updateProvider({
          data: {
            method: "oidc",
            flow: flowId,
            unlink: attributes.value
          }, 
          flowId
        }, {
          onSuccess: () => {
            fetchSettings().catch(console.error);
          }
        });
      }

      
    }
  }, [attributes, flowId]);

  return (
    <>
      <SettingsItem 
        title={title} 
        icon={<GetIcon type={attributes.value} />}
        button={attributes.name === "link" ? 
        <GrnBtn width="150px" onClick={onAction}>Connect</GrnBtn>
        :
        <DarkRedButton width="150px" onClick={onAction}>Disconnect</DarkRedButton>
        }
      />
      <DividerContainer />
    </>
  )
};

export default AccountItem;