import { Identity } from "@ory/client";
import {
  useApiUrl,
  useCreate,
  useCustom,
  useDelete,
  useGetIdentity,
  useList,
  useUpdate,
} from "@pankod/refine-core";
import { ICommunity, ICommunityData } from "interfaces";
import { useContext, useMemo } from "react";
import _ from "lodash";
import useObjectArrayEffect from "../hooks/useObjectArrayEffect";
import { StoreContext } from "../context/store";

export const getCommunitiesWithUpdate = () => {
  const apiUrl = useApiUrl("rest");
  const [activeCommunityId, setActiveCommunity] = useContext(StoreContext);
  const { data: identity } = useGetIdentity<Identity>();
  const { mutate: insertAction } = useCreate<ICommunity>();
  const { mutate: deleteAction } = useDelete();
  const { mutate: updateAction } = useUpdate<ICommunity>();

  const identityId = useMemo(() => identity?.id || "no-id", [identity]);

  const { data: socialCommunitiesResponse, isLoading: isSocialLoading } =
    useCustom<ICommunityData[]>({
      dataProviderName: "rest",
      url: `${apiUrl}/communities`,
      method: "get",
      config: {
        query: {
          identityId: identityId,
        },
      },
    });

  const { data: connectedCommunitiesResponse, isLoading: isConnectedLoading } =
    useList<ICommunity>({
      resource: "communities",
      config: {
        filters: [
          {
            field: "manager_id",
            operator: "eq",
            value: identityId,
          },
        ],
      },
      metaData: {
        fields: ["id", "communityData", "manager_id"],
      },
    });

  const socialCommunities = useMemo(() => {
    return socialCommunitiesResponse?.data.map((it) => it);
  }, [socialCommunitiesResponse]);

  const connectedCommunities = useMemo(
    () => connectedCommunitiesResponse?.data.map((it) => it),
    [connectedCommunitiesResponse]
  );

  const needToAdd = useMemo(() => {
    if (
      !socialCommunities ||
      !connectedCommunities ||
      identityId === "no-id" ||
      isConnectedLoading ||
      isSocialLoading ||
      !socialCommunitiesResponse ||
      !connectedCommunitiesResponse
    )
      return [];

    console.log("Social", socialCommunities);
    console.log("Connected", connectedCommunities);

    return _.differenceWith(
      socialCommunities,
      connectedCommunities,
      (sc, cc) =>
        sc.source === cc.communityData.source && identityId === cc.manager_id
    );
  }, [
    socialCommunities,
    connectedCommunities,
    identityId,
    isSocialLoading,
    isConnectedLoading,
    socialCommunitiesResponse,
    connectedCommunitiesResponse,
  ]);

  const needToDelete = useMemo(() => {
    if (
      !socialCommunities ||
      !connectedCommunities ||
      identityId === "no-id" ||
      isConnectedLoading ||
      isSocialLoading ||
      !connectedCommunitiesResponse ||
      !socialCommunitiesResponse
    )
      return [];

    return _.differenceWith(
      connectedCommunities,
      socialCommunities,
      (cc, sc) =>
        cc.communityData.source === sc.source && identityId === cc.manager_id
    );
  }, [
    socialCommunities,
    connectedCommunities,
    identityId,
    isSocialLoading,
    isConnectedLoading,
    connectedCommunitiesResponse,
    socialCommunitiesResponse,
  ]);

  const needToUdpate = useMemo(() => {
    if (
      !socialCommunities ||
      !connectedCommunities ||
      identityId === "no-id" ||
      isConnectedLoading ||
      isSocialLoading ||
      !connectedCommunitiesResponse ||
      !socialCommunitiesResponse
    )
      return [];

    return connectedCommunities
      .map((it) => {
        const originalCommunity = socialCommunities.find(
          (sc) => sc.source === it.communityData.source
        );

        if (
          !originalCommunity ||
          _.isEqual(originalCommunity, it.communityData)
        )
          return null;

        return {
          ...it,
          communityData: originalCommunity,
        };
      })
      .filter(Boolean);
  }, [
    socialCommunities,
    connectedCommunities,
    identityId,
    !connectedCommunitiesResponse,
    !socialCommunitiesResponse,
    isConnectedLoading,
    isSocialLoading,
  ]);

  useObjectArrayEffect<ICommunityData>(() => {
    console.log(needToAdd);
    if (identityId && needToAdd && needToAdd.length) {
      needToAdd.forEach((it) => {
        insertAction({
          resource: "communities",
          values: {
            manager_id: identityId,
            communityData: { ...it },
          },
        });
      });
    }
  }, needToAdd);

  useObjectArrayEffect<ICommunity>(() => {
    if (identityId && needToDelete && needToDelete.length) {
      needToDelete.forEach((it) => {
        deleteAction({
          resource: "communities",
          id: it.id,
        });
      });
    }
  }, needToDelete);

  useObjectArrayEffect(() => {
    const communityId = localStorage.getItem("activeCommunity");
    if (
      connectedCommunities &&
      connectedCommunities.length &&
      !isConnectedLoading
    ) {
      if (
        needToDelete.some((it) => it.id == communityId) ||
        connectedCommunities?.every((it) => it.id != communityId)
      ) {
        localStorage.removeItem("activeCommunity");
        setActiveCommunity("");
      }
    }
  }, [needToDelete, connectedCommunities, isConnectedLoading]);

  useObjectArrayEffect<ICommunity | null>(() => {
    if (identityId && needToUdpate && needToUdpate.length) {
      needToUdpate.forEach((it) => {
        updateAction({
          resource: "communities",
          id: it?.id || "",
          values: {
            communityData: { ...it?.communityData },
          },
        });
      });
    }
  }, needToUdpate);

  useObjectArrayEffect(() => {
    if (
      !activeCommunityId.length &&
      connectedCommunities &&
      connectedCommunities.length &&
      !isConnectedLoading
    ) {
      setActiveCommunity(connectedCommunities[0].id);
    }
  }, [activeCommunityId, connectedCommunities, isConnectedLoading]);

  return {
    isLoading: isSocialLoading && isConnectedLoading,
    communities: connectedCommunities,
  };
};
