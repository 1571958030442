import {AxiosInstance} from "@pankod/refine-simple-rest/node_modules/axios";
import { REST_API_URL } from "./helpers/api-urls";
import axiosInstance from "./http_clients/axios-client";

type UploadOneProps = {
  file: Blob;
  folderName: string;
  fileName: string;
};

type DeleteOneProps = {
  filePath: string;
}

const FileDataProvider = (apiUrl: string, httpClient: AxiosInstance)  => {
  return {
    uploadOne: async ({ file, fileName, folderName }: UploadOneProps): Promise<string> => {
      const url = `${apiUrl}/file/upload`;
      const formData = new FormData();

      formData.append("communityId", folderName);
      formData.append("fileName", fileName);
      formData.append("file", file);

      const {data} = await httpClient.post(url, formData);

      return data.url;
    },
    deleteOne: async ({ filePath }: DeleteOneProps) => {
      const url = `${apiUrl}/file/delete`;
      const { data } = await httpClient.post(url, {path: filePath});

      return data;
    },
  };
}

export default FileDataProvider(REST_API_URL, axiosInstance);