import { Authenticated } from "@pankod/refine-core";
import { ContentWrapper } from "../../components/styled";
import { EmptyImageText } from "../../pages/dashboard/styled";
import emptyImage from "../../public/images/empty.png";
import SimpleHeader from "../simple-header";
import { useModal } from "@pankod/refine-antd";
import ConnectCommunityModal from "../../components/connect-modal";
import { NoneContent, NoneImageContainer } from "./styled";

const NoneCommunities: React.FC = () => {
  const { show, modalProps } = useModal();
  const showConnectCommunityModal = () => {
    show();
  };
  return (
    <Authenticated>
      <SimpleHeader
        title="Dashboard"
        hasPrimaryButton
        primaryButtonText="Connect community"
        onPrimaryButtonClick={showConnectCommunityModal}
      />
      <ContentWrapper>
        <NoneContent>
          <NoneImageContainer>
            <img src={emptyImage} />
            <EmptyImageText>There's nothing here yet.</EmptyImageText>
          </NoneImageContainer>
        </NoneContent>
      </ContentWrapper>
      <ConnectCommunityModal modalProps={modalProps} />
    </Authenticated>
  );
};

export default NoneCommunities;
