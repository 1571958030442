import { AxiosInstance } from "@pankod/refine-simple-rest/node_modules/axios";
import dataProvider from "@pankod/refine-simple-rest";
import { stringify } from "query-string";
import axiosInstance from "./http_clients/axios-client";
import { REST_API_URL } from "./helpers/api-urls";

interface DeleteProps {
  resource: string;
  id: string;
  variables: any;
};

const CustomDataProvider = (apiUrl: string, httpClient: AxiosInstance) => {
  const simpleRestProvider = dataProvider(apiUrl, httpClient);
  return {
    ...simpleRestProvider,
    deleteOne: async ({ resource, id, variables }: DeleteProps) => {
      const requestUrl = `${apiUrl}/${resource}?`;
      const url = `${requestUrl}&${stringify(variables)}`;

      const { data } = await httpClient.delete(url, { data: variables });

      return {
        data
      };
    },
  };
};

export default CustomDataProvider(REST_API_URL, axiosInstance);
