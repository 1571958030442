import { ModalContainer, ModalCloseIcon } from "./styled";
import type { ModalProps } from "@pankod/refine-antd";

interface ModalPropsType {
  modalProps?: ModalProps;
  children?: any;
  width?: string;
  height?: string;
  disableCloseIcon?: boolean;
}

const Modal: React.FC<ModalPropsType> = ({
  modalProps,
  children,
  width,
  height,
  disableCloseIcon,
}) => (
  <ModalContainer
    width={width}
    height={height}
    bodyStyle={{
      height: "100%",
      width: "100%",
      margin: 0,
      background: "none",
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    footer={null}
    closable={!disableCloseIcon}
    closeIcon={!disableCloseIcon ? <ModalCloseIcon /> : null}
    maskStyle={{ backdropFilter: "blur(5px)" }}
    centered={true}
    {...modalProps}
  >
    {children}
  </ModalContainer>
);

export default Modal;
