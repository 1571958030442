import { useModal } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { useCallback } from "react";
import { ORANGE } from "../../constants/style";
import { H4Text, OrngBtn } from "../default.styled";
import Modal from "../modal/modal";
import { DarkRedButton } from "../styled";
import { 
  ModalButton,
  ModalButtonLight,
  ModalButtonsContainer,
  ModalMemberName,
  ModalTitle
} from "./styled";

interface ApproveButtonProps {
  requestId: string;
  memberName: string;
}

const RejectButton: React.FC<ApproveButtonProps> = ({
  requestId,
  memberName
}) => {
  const { mutate: reject } = useUpdate();
  const { show, close, modalProps } = useModal();

  const handleClick = useCallback(() => {
    reject({
      resource: "requests",
      values: {
        status: "rejected",
      },
      id: requestId,
    });
    close();
  }, [requestId]);

  return (
    <>
      <DarkRedButton width="auto" onClick={show}>
        Reject
      </DarkRedButton>
      <Modal
        modalProps={modalProps}
        width="550px"
        height="250px"
        disableCloseIcon
      >
        <ModalTitle>Reject user</ModalTitle>
        <H4Text>
          Are you shure you want to reject
          <ModalMemberName>{memberName}</ModalMemberName>{" "}?
        </H4Text>
        <ModalButtonsContainer>
          <ModalButton onClick={close}>Cancel</ModalButton>
          <OrngBtn width="auto" onClick={handleClick}>Reject</OrngBtn>
        </ModalButtonsContainer>
      </Modal>
    </>
  );
};  

export default RejectButton;