import { ModalProps, RcFile } from "@pankod/refine-antd";
import Modal from "../../../components/modal/modal";
import {
  ImageIcon,
  Title,
  Dragger,
  UploadTitleContainer,
  UploadTitle,
  UploadTitleGreen,
  UploadSubtitle
} from "../styled";

type UploadWindowProps = {
  modalProps: ModalProps,
  uploadHandler: (file: RcFile, FileList: RcFile[]) => any
}

const UploadWindow: React.FC<UploadWindowProps> = ({modalProps, uploadHandler}) => (
  <Modal modalProps={modalProps} width="500px" height="360px">
    <Title>Upload Image</Title>
    <Dragger
      accept="image/png, image/jpeg, image/svg"
      showUploadList={false}
      beforeUpload={uploadHandler}
   >
      <UploadTitleContainer>
        <ImageIcon />
        <UploadTitle>Drag and drop or <UploadTitleGreen>browse</UploadTitleGreen></UploadTitle>
      </UploadTitleContainer>
      <UploadSubtitle>Supports: JPG or PNG. Max. file size 2 mb</UploadSubtitle>
    </Dragger>
  </Modal>
);

export default UploadWindow;