import { textEditorIcons } from "../../public/icons";
import { ToolbarBtn, ToolbarContainer, ToolbarIcon } from "./styled";
import Quill from "quill";

import "quill/dist/quill.snow.css";

const icons = Quill.import("ui/icons");
const {
  boldIcon,
  bulletedListIcon,
  hOneIcon,
  hTwoIcon,
  hThreeIcon,
  italicIcon,
  linkIcon,
  numberedListIcon,
  strikethroughIcon,
  underlineIcon,
} = textEditorIcons;

const Toolbar: React.FC = () => {
  icons["bold"] = <ToolbarIcon src={boldIcon} />;
  icons["italic"] = <ToolbarIcon src={italicIcon} />;
  icons["underline"] = <ToolbarIcon src={underlineIcon} />;
  icons["strike"] = <ToolbarIcon src={strikethroughIcon} />;
  icons["link"] = <ToolbarIcon src={linkIcon} />;
  icons["list"] = <ToolbarIcon src={bulletedListIcon} />;
  icons["header"] = <ToolbarIcon src={hOneIcon} />;
  return (
    <ToolbarContainer id="toolbar">
      <ToolbarBtn className="ql-bold">
        <ToolbarIcon src={boldIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-italic">
        <ToolbarIcon src={italicIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-underline">
        <ToolbarIcon src={underlineIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-strike">
        <ToolbarIcon src={strikethroughIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-list" value="bullet">
        <ToolbarIcon src={bulletedListIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-list" value="ordered">
        <ToolbarIcon src={numberedListIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-link">
        <ToolbarIcon src={linkIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-header" value="1">
        <ToolbarIcon src={hOneIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-header" value="2">
        <ToolbarIcon src={hTwoIcon} />
      </ToolbarBtn>
      <ToolbarBtn className="ql-header" value="3">
        <ToolbarIcon src={hThreeIcon} />
      </ToolbarBtn>
    </ToolbarContainer>
  );
};

export default Toolbar;
