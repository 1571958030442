import { Label, SimpleText } from "../../components/styled";
import ContextMenu from "./context-menu";
import {
  OnboardingItem as Item,
  CaptionContainer,
  OnboardingIcon,
  OnboardingControls,
  PaymentAttention,
  AttentionIcon
} from "./styled";

import type { OnboardingType } from "./types";

type OnboardingItemProps = {
  label: string;
  icon: string;
  hasPayment?: boolean;
  type: OnboardingType;
  onDelete: (type: OnboardingType) => Promise<void>;
};

const OnboardingItem: React.FC<OnboardingItemProps> = ({
  label,
  icon,
  type,
  onDelete,
}) => (
  <Item>
    <CaptionContainer>
      <OnboardingIcon src={icon} isActive />
      <Label>{label}</Label>
    </CaptionContainer>
    <OnboardingControls>
      {
        //will be remove after payment connections
        true ? null : (
          <PaymentAttention>
            <AttentionIcon />
            <SimpleText>Connect stripe to collect payments</SimpleText>
          </PaymentAttention>
        )
      }
      <ContextMenu type={type} onDelete={onDelete} />
    </OnboardingControls>
  </Item>
);

export default OnboardingItem;
