import { useCallback, useContext, useMemo } from "react";
import { useNavigation, useOne, useUpdate, useUpdatePassword } from "@pankod/refine-core";
import { ICommunity } from "interfaces";
import fileProvider from "../../data_provider/file-provider";
import {
  Content,
  EmtpyOnboardingContainer,
  OnboardingContainer,
  OnboardingHeader,
  EmptyImageText,
  OnboardingText,
  OnboardingItemsContainer,
} from "./styled";
import emptyImage from "../../public/images/empty.png";
import OnboardingItem from "./onboarding-item";
import { widgetIcon, worldIcon } from "../../public/icons";
import { StoreContext } from "../../context/store";
import { GrnBtn } from "../../components/default.styled";

import type { OnboardingType } from "./types";
import { Button } from "@pankod/refine-antd";

const DashboardContent: React.FC = () => {
  const [activeCommunityId, setActiveCommunity] = useContext(StoreContext);
  const { mutate: updateMutation } = useUpdate();
  const { push } = useNavigation();
  const openConstructor = () => {
    push("constructor");
  };

  const { data: communityResponse } = useOne<ICommunity>({
    resource: "communities",
    id: activeCommunityId,
    metaData: {
      fields: ["id", "communityData", "landingData", "widgetData"],
    },
  });

  const community = useMemo<ICommunity | undefined>(
    () => communityResponse?.data,
    [communityResponse]
  );

  const hasLanding = useMemo(() => {
    if (community) {
      return community.landingData !== null;
    }

    return false;
  }, [community]);

  const hasWidget = useMemo(() => {
    if (community) {
      return community.widgetData !== null;
    }

    return false;
  }, [community]);

  const deleteOnboarding = useCallback(
    async (type: OnboardingType) => {
      if (!community) return;

      const { landingData, widgetData } = community;

      switch (type) {
        case "landing":
          await fileProvider.deleteOne({
            filePath: `${activeCommunityId}/${landingData?.backgroundSrc?.name}`,
          });
          if (
            !hasWidget ||
            widgetData.logoSrc?.url !== landingData.logoSrc?.url
          ) {
            await fileProvider.deleteOne({
              filePath: `${activeCommunityId}/${landingData?.logoSrc?.name}`,
            });
          }

          updateMutation({
            resource: "communities",
            id: activeCommunityId,
            values: {
              landingData: null,
            },
          });
          break;
        case "widget":
          if (
            !hasLanding ||
            widgetData.logoSrc?.url !== landingData.logoSrc?.url
          ) {
            await fileProvider.deleteOne({
              filePath: `${activeCommunityId}/${widgetData?.logoSrc?.name}`,
            });
          }

          updateMutation({
            resource: "communities",
            id: activeCommunityId,
            values: {
              widgetData: null,
            },
          });
          break;
        case "questionnaire":
          updateMutation({
            resource: "communities",
            id: activeCommunityId,
            values: {
              questionnaire: null,
            },
          });
          break;
      }
    },
    [activeCommunityId, hasWidget, hasLanding, community]
  );

  return (
    <Content>
      <OnboardingContainer>
        <OnboardingHeader>
          <OnboardingText>User onboarding</OnboardingText>
          <GrnBtn width="235px" onClick={openConstructor}>
            Create user onboarding
          </GrnBtn>
        </OnboardingHeader>
        <OnboardingItemsContainer>
          {
            hasLanding && 
            (
              <OnboardingItem
                label="Landing page"
                icon={worldIcon}
                type="landing"
                onDelete={deleteOnboarding}
              />
            )
          }
          {
            hasWidget &&
            (
              <OnboardingItem
                label="Widget"
                icon={widgetIcon}
                type="widget"
                onDelete={deleteOnboarding}
              />
            )
          }
          {
            !hasLanding && !hasWidget &&
            (
              <EmtpyOnboardingContainer>
                <img src={emptyImage} />
                <EmptyImageText>There's nothing here yet.</EmptyImageText>
              </EmtpyOnboardingContainer>
            )
          }
        </OnboardingItemsContainer>
        
      </OnboardingContainer>
    </Content>
  );
};

export default DashboardContent;
