import {discordIcon, googleIcon, slackIcon} from "../public/icons";
import { SelectIcon } from "./styled";

const GetIcon: React.FC<{ type: string }> = ({ type }) => {
  switch (type) {
    case "discord":
      return <SelectIcon src={discordIcon} />;
    case "slack":
      return <SelectIcon src={slackIcon} />;
    case "google":
      return <SelectIcon src={googleIcon} />
    default:
      return null;
  }
};

export default GetIcon;