import { UiNodeInputAttributes } from "@ory/client";
import { ModalProps } from "@pankod/refine-antd";
import { useEffect, useMemo, useState } from "react";
import ory from "../data_provider/http_clients/ory-client";
import { discordIcon, slackIcon } from "../public/icons";
import { DarkBtn } from "./default.styled";
import Modal from "./modal/modal";
import { ConnectModalContainer } from "./sider/styled";
import { SelectIcon } from "./styled";

interface ConnectModalProps {
  modalProps: ModalProps
}

const ConnectCommunityModal: React.FC<ConnectModalProps> = ({modalProps}) => {
  const [oidcProviders, setOidcProviders] = useState<UiNodeInputAttributes[]>([]);

  const fetchSettings = async () => {
    const {data: {id: flowId}} =  await ory.initializeSelfServiceSettingsFlowForBrowsers();

    const {data: {ui: {nodes}}} = await ory.getSelfServiceSettingsFlow(flowId);

    const oidcProviders = nodes
      .filter(it => it.group === "oidc")
      .map(it => it.attributes as UiNodeInputAttributes);
      
    setOidcProviders(oidcProviders)
  };

  const showDiscord = useMemo(() => {
    const discordProvider = oidcProviders.find(it => it.value === "discord");

    if (!discordProvider) return true;

    return discordProvider.name === "unlink";
  }, [oidcProviders]);

  const showSlack = useMemo(() => {
    const googleProvider = oidcProviders.find(it => it.value === "google");

    if (!googleProvider) return true;

    return googleProvider.name === "unlink";
  }, [oidcProviders]);

  useEffect(() => {
    fetchSettings().catch(console.error);
  }, [])

  const onConnectDiscord = () => {
    window.open(
      "https://discord.com/api/oauth2/authorize?client_id=1042785795603447848&permissions[…]_type=code&scope=identify%20email%20bot%20applications.commands",
      "_blank",
      "noreferrer"
    );
  };

  const onConnectSlack = () => {
    window.open(
      `https://slack.com/oauth/v2/authorize?client_id=4245311686660.4317105068118&scope=channels:history,channels:join,channels:manage,channels:read,chat:write,conversations.connect:write,groups:read,groups:write,im:read,im:write,incoming-webhook,mpim:read,mpim:write,team:read,users:read,users:read.email,conversations.connect:manage&user_scope=channels:read,channels:write,team:read,users.profile:read`,
      "_self"
    );
  };

  return (
    <Modal modalProps={modalProps} height="240px" width="500px">
      <ConnectModalContainer>
        {
          showDiscord && 
          <DarkBtn
            icon={<SelectIcon src={discordIcon} />}
            width="80%"
            onClick={onConnectDiscord}
          >
            Discord
          </DarkBtn>
        }
        {
          showSlack && 
          <DarkBtn 
            icon={<SelectIcon src={slackIcon} />}
            width="80%"
            onClick={onConnectSlack}
          >
            Slack
          </DarkBtn>
        }
      </ConnectModalContainer>
    </Modal>
  );
};

export default ConnectCommunityModal;