import styled from "styled-components";
import { BackIcon, ContentWrapper, Icon } from "../../components/styled";
import { GREEN_FILTER } from "../../constants/filters";
import { INPUT_BG_HOV_CLR } from "../../constants/style";

export const SettingsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ForwardButton = styled(BackIcon)`
  transform: rotate(180deg);
  cursor: pointer;
`;

export const SettingsItemTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const SettingsIcon = styled(Icon)`
  margin-right: 0;
  ${GREEN_FILTER}
`;
export const ClickableArea = styled.div`
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
    transition: transform 0.5s ease-in-out;
  }
`;
