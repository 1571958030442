import { useCallback, useContext } from "react";
import { Select } from "@pankod/refine-antd";
import { SelectOption } from "interfaces";
import { StoreContext } from "../../context/store";
import {
  SelectorWrapper,
  SelectContainer,
  SelectOptionStyles,
  DropdownContainer
} from "./styled";
import { DARK_BORDER_COLOR, GRAY_COLOR } from "../../constants/style";
import { useNavigation } from "@pankod/refine-core";
import ConnectButton from "./connect-button";
import GetIcon from "../get-icon";

type SelectBoxProps = {
  options: SelectOption[];
  isLoading: boolean;
};

const SelectBox: React.FC<SelectBoxProps> = ({ options, isLoading }) => {
  const [activeCommunityId, setActiveCommunity] = useContext(StoreContext);
  const { push } = useNavigation();
  const onChangeCommunity = useCallback(
    (value: any) => {
      setActiveCommunity(value);
      localStorage.setItem("activeCommunity", value);
      push("/");
    },
    [setActiveCommunity]
  );

  return (
    <SelectorWrapper>
      {!isLoading && activeCommunityId.length ? (
        <SelectContainer
          placement="bottomLeft"
          onChange={onChangeCommunity}
          value={activeCommunityId}
          dropdownAlign={{ offset: [-17, 4] }}
          dropdownRender={(menu) => (
            <DropdownContainer>
              {menu}
              <ConnectButton />
            </DropdownContainer>
          )}
          dropdownStyle={{
            minWidth: "210px",
            background: `${GRAY_COLOR}`,
            border: `1px solid ${DARK_BORDER_COLOR}`,
            borderRadius: "20px",
          }}
          bordered={false}
        >
          {options.map((it) => (
            <Select.Option
              key={it.value}
              value={it.value}
              style={{ ...SelectOptionStyles }}
            >
              <GetIcon type={it.type || ""} />
              {it.label}
            </Select.Option>
          ))}
        </SelectContainer>
      ) : null}
    </SelectorWrapper>
  );
};

export default SelectBox;
