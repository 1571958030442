import { notification } from "@pankod/refine-antd";
import { SuccessIconWrapper, SuccessIcon } from "./styled";

export const successCopyNotification = (key: string) => {
  notification.open({
    message: "Success!",
    description: "The text is copied.",
    placement: "bottomRight",
    key: key,
    className: "custom-notification",
    duration: 2,
    icon: (
      <SuccessIconWrapper>
        <SuccessIcon />
      </SuccessIconWrapper>
    ),
  });
};
