import { RequestStatus } from "interfaces";
import { useCallback } from "react";
import { RequestsQuantity } from "../../components/styled";
import { FilterStatusButton } from "./styled";

interface FilterControlProps {
  title: string;
  value: RequestStatus;
  onAction: (value: RequestStatus) => void;
  isActive: boolean;
  requestsQuantity?: number;
}

const FilterControl: React.FC<FilterControlProps> = ({
  title, 
  value, 
  onAction, 
  isActive,
  requestsQuantity = 0
}) => {
  const onClick = useCallback(() => {
    onAction(value);
  }, [onAction, value]);

  return (
    <FilterStatusButton 
      $isActive={isActive}
      onClick={onClick} 
      icon={requestsQuantity > 0 ? <RequestsQuantity>{requestsQuantity}</RequestsQuantity> : null}
    >
      {title}
    </FilterStatusButton>
  );
}

export default FilterControl;