import ReactQuill from "react-quill";
import { useCallback, useEffect, useState } from "react";
import Toolbar from "./toolbar";
import {
  EditorContainer,
  TextEditorContainer,
  ButtonsContainer,
  ModalButton,
  ModalButtonLight,
} from "./styled";

import "quill/dist/quill.snow.css";

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];

interface TextEditorProps {
  value: any;
  onClose?: () => void;
  onDone: () =>  void;
  onChange: (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => void;
};

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onClose,
  onDone,
  onChange
}) => (
  <>
    <TextEditorContainer>
      <Toolbar />
      <EditorContainer>
        <ReactQuill
          placeholder="Enter code of conduct"
          value={value}
          onChange={onChange}
          modules={{toolbar: {container: "#toolbar"}}}
          formats={formats}
        />
      </EditorContainer>
      <ButtonsContainer>
        <ModalButton onClick={onClose}>Cancel</ModalButton>
        <ModalButtonLight onClick={onDone}>Done</ModalButtonLight>
      </ButtonsContainer>
    </TextEditorContainer>
  </>
);

export default TextEditor;
