import { V0alpha2Api, Configuration } from "@ory/client";


export default new V0alpha2Api(
  new Configuration({
    basePath: process.env.NODE_ENV !== "dev" ? `${import.meta.env.VITE_API_URL}/auth` : "http://localhost:4433",
    baseOptions: {
      withCredentials: true
    }
  }),
);