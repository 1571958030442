import { useList } from "@pankod/refine-core";
import { ISlackMember } from "interfaces";
import MembersContent from "./members-content";
import { MembersContetBaseProps } from "./types";

const SlackMembersHoc: React.FC<MembersContetBaseProps> = (props) => {
  const {data: slack_members, isLoading} = useList<ISlackMember>({
    resource: "slack_members",
    config: {
      filters: [
        {
          field: "teamId",
          operator: "eq",
          value: props.communityData.source
        },
        {
          field: "name",
          operator: "startswith",
          value: props.searchValue
        }
      ],
      pagination: {
        pageSize: 50
      }
    },
    metaData: {
      fields: ["id", "name", "teamId", "slackUserId", "email", "avatar"]
    }
  });

  if (isLoading) return null;

  return (
    <MembersContent 
      {...props}
      slackMembers={slack_members?.data}
    />
  )

}

export default SlackMembersHoc;