import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { GREEN_COLOR, ORANGE } from "../../constants/style";
import { SelectOption } from "./styled";

type ChannelSelectOptionProps = {
  name: string,
  isPublic?: boolean
}

const ChannelSelectOption: React.FC<ChannelSelectOptionProps> = ({
  name,
  isPublic
}) => (
  <SelectOption>
    {name}
    {isPublic ? (
      <CheckOutlined style={{ color: GREEN_COLOR }} />
    ) : (
      <ExclamationCircleOutlined style={{ color: ORANGE }} />
    )}
  </SelectOption>
);

export default ChannelSelectOption;