import { DashboardPageProps } from "@pankod/refine-core/dist/interfaces";
import Header from "./header";
import Content from "./content";
import NoneCommunities from "../../components/none-communities";
import { getCommunities } from "../../components/getCommunities";

const Dashboard: React.FunctionComponent<DashboardPageProps<any>> = () => {
  const { isLoading, communities } = getCommunities();
  return (
    <>
      {!isLoading && !communities || 
        !isLoading && communities && !communities.data.length 
        ? (
        <NoneCommunities />
        ) : (
        <>
          <Header />
          <Content />
        </>
      )}
    </>
  );
};

export default Dashboard;
