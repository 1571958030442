import {  AntdList, useSimpleList } from "@pankod/refine-antd";
import { Authenticated, useList } from "@pankod/refine-core";
import { IRequest, RequestStatus } from "interfaces";
import { useContext, useState } from "react";
import MemberCard from "../../components/member-card/member-card";
import { MemberCardActionType } from "../../components/member-card/types";
import { StoreContext } from "../../context/store";
import RequestsHeader from "./header";

const Requests = () => {
  const [activeCommunity, _] = useContext(StoreContext);
  const [statusFilter, setStatusFilter] = useState<RequestStatus>("none");

  const {data} = useList<IRequest>({
    resource: "requests",
    metaData:{
      fields: ["id"]
    },
    config: {
      filters: [
        {
          field: "community_id",
          operator: "eq",
          value: activeCommunity
        },
        {
          field: "status",
          operator: "eq",
          value: "none"
        }
      ]
    }
  });

  const { listProps, setFilters } = useSimpleList<IRequest>({
    resource: "requests",
    hasPagination: false,
    permanentFilter: [
      {
        field: "community_id",
        operator: "eq",
        value: activeCommunity,
      },
      {
        field: "status",
        operator: "eq",
        value: statusFilter
      }
    ],
    metaData: {
      fields: ["id", "community_id", "name", "image", "email", "status", "community { communityData }"]
    }
  });

  const renderItem = (item: IRequest) => {
    let actionTypes = [] as MemberCardActionType[];

    if (statusFilter === "approved" || statusFilter === "none") {
      actionTypes.push("reject");
    }

    if (statusFilter === "rejected" || statusFilter === "none") {
      actionTypes.push("approve");
    }

    return (
      <MemberCard
        item={{
          title: item.name,
          email: item.email,
          image: item.image,
          id: item.id
        }}
        actionTypes={actionTypes}
        communitySourceId={item.community?.communityData.source}
        type={item.community?.communityData.type || ""}
      />
    )
  }

  return (
    <Authenticated>
      <RequestsHeader
        setStatusFilter={setStatusFilter}
        setFilters={setFilters}
        statusFilter={statusFilter}
        newRequestsNumber={data?.total || 0}
      />
     <AntdList
        className="decorated-scroll"
        style={{
          padding: "0 40px",
          overflowY: "auto"
        }}
        {...listProps} 
        renderItem={renderItem}
        
      />
    </Authenticated>
  )
}

export default Requests;