import { Refine } from "@pankod/refine-core";
import { ReadyPage } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { StoreProvider } from "./context/store";
import Layout from "./components/layout";
import LoginPage from "./pages/login";
import Dashboard from "./pages/dashboard";
import Constructor from "./pages/constructor";
import PreviewPage from "./pages/preview";
import Members from "./pages/members";
import NotFound from "./components/404/not-found";
import authProvider from "./auth-provider";
import dataProvider from "./data_provider";

import { requestsIcon, settingsIcon } from "./public/icons";

import "@pankod/refine-antd/dist/styles.min.css";
import Settings from "./pages/settings";
import ConnectedCommunities from "./pages/connected-communities";
import Accounts from "./pages/accounts";
import Requests from "./pages/requests";

const App: React.FC = () => {
  return (
    <StoreProvider>
      <Refine
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Constructor />,
              path: "/constructor",
              layout: true,
            },
            {
              element: <Members />,
              path: "/members",
              layout: true,
            },
            {
              element: <PreviewPage />,
              path: "/preview",
              layout: false,
            },
            {
              element: <ConnectedCommunities />,
              path: "/connected-communities",
              layout: true
            },
            {
              element: <Accounts />,
              path: "/accounts",
              layout: true
            }
          ] as typeof routerProvider.routes,
        }}
        authProvider={authProvider}
        dataProvider={dataProvider}
        LoginPage={LoginPage}
        Layout={Layout}
        DashboardPage={Dashboard}
        ReadyPage={ReadyPage}
        catchAll={<NotFound />}
        resources={[
          {
            name: "requests",
            icon: requestsIcon,
            list: Requests
          },
          {
            name: "settings",
            icon: settingsIcon,
            list: Settings,
          }
        ]}
      />
    </StoreProvider>
  );
};

export default App;
