import { List } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import MembersHeaderActions from "./members-header-actions";
import { MemberCardsContainer, MembersHeaderTitleText } from "./styled";
import { DARK_COLOR } from "../../constants/style";
import { BackIcon, HeaderBackButton } from "../../components/styled";
import { ICommunityData } from "interfaces";
import { ReactNode } from "react";
import { SelectOption } from "./types";

interface MembersListProps {
  communityData: ICommunityData,
  selectedChannel: string,
  setSearchValue: any,
  setSelectedChannel: (value: string) => void,
  children: ReactNode,
  channelOptions: SelectOption[],
  downloadMembers: () => void
};

const MembersList: React.FC<MembersListProps> = ({
  communityData,
  selectedChannel,
  setSearchValue,
  setSelectedChannel,
  children,
  channelOptions,
  downloadMembers
}) => {
  const { goBack } = useNavigation();

  return (
    <List
      pageHeaderProps={{
        style: {
          padding: "0",
        },
        backIcon: (
          <HeaderBackButton>
            <BackIcon />
          </HeaderBackButton>
        ),
        onBack: (): void => goBack(),
      }}
      headerProps={{
        style: {
          position: "relative",
          padding: "0",
          backgroundColor: DARK_COLOR,
          maxHeight: "100%",
        },
      }}
      wrapperProps={{
        style: {
          padding: "0",
        },
      }}
      title={<MembersHeaderTitleText>Members</MembersHeaderTitleText>}
      headerButtons={() => (
        <MembersHeaderActions
          handleDownload={downloadMembers}
          channelOptions={channelOptions}
          communityData={communityData}
          setSearchValue={setSearchValue}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
        />
      )}
    >
      <MemberCardsContainer className="decorated-scroll">
        {children}
      </MemberCardsContainer>
    </List>
  );
};

export default MembersList;
