import {
  Wrapper,
  SimpleText,
  GreenButton,
  NotFoundContent,
  NotFoundTitle,
} from "./styled";
import NotFoundImage from "../../public/images/404.png";
import { useMemo } from "react";
import { Image } from "@pankod/refine-antd";
import { GRAY_COLOR } from "../../constants/style";
import { GlobalStyle } from "../default.styled";
import { useNavigation } from "@pankod/refine-core";

type NotFoundProps = {
  isSmall?: boolean;
};

const NotFound: React.FC<NotFoundProps> = ({ isSmall }) => {
  const imageWidth = useMemo<number>(() => (isSmall ? 150 : 330), [isSmall]);
  const { push } = useNavigation();
  return (
    <Wrapper>
      <GlobalStyle />
      <NotFoundContent>
        <Image alt="404" src={NotFoundImage} width={imageWidth} />
        <NotFoundTitle $fontSize="35px">Page not found</NotFoundTitle>
        <SimpleText>
          Sorry, we can't find the page you're looking for.
          <br /> Please go back to the homepage.
        </SimpleText>
        <GreenButton
          $marginTop={isSmall ? "10px" : "50px"}
          width="114px"
          color={GRAY_COLOR}
          onClick={() => push("/")}
        >
          Go home
        </GreenButton>
      </NotFoundContent>
    </Wrapper>
  );
};

export default NotFound;
