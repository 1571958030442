import { useCallback, useMemo } from "react";
import { BackIcon, HeaderBackButton } from "../../components/styled";
import {
  Steps,
  Step as StepItem,
  Divider,
  OkIcon,
  ConstructorHeader as Header
} from "./styled";
import { StepStage, StepState } from "./types";

interface StepProps {
  content: React.ReactNode;
  state: StepState;
  isActiveDivider?: boolean;
  isLast?: boolean;
}

const Step: React.FC<StepProps> = ({
  content,
  state,
  isLast,
  isActiveDivider,
}) => (
  <>
    <StepItem state={state}>{content}</StepItem>
    {isLast ? null : <Divider isActive={isActiveDivider} />}
  </>
);

const ConstructorHeader: React.FC<{
  stage: StepStage;
  changeStage: (stage: StepStage) => void;
}> = ({ stage, changeStage }) => {
  const isBeginStage = useMemo<boolean>(() => stage === "begin", [stage]);
  const isFinishStage = useMemo<boolean>(() => stage === "finish", [stage]);

  const secondStepState = useMemo<StepState>(
    () => (isBeginStage ? "waiting" : isFinishStage ? "done" : "active"),
    [isBeginStage, isFinishStage]
  );

  const onBackButton = useCallback(() => {
    changeStage("begin");
  }, [changeStage]);

  return (
    <Header>
      {stage === "constructor" && (
        <HeaderBackButton onClick={onBackButton}>
          <BackIcon />
        </HeaderBackButton>
      )}
      <Steps>
        <Step
          state={isBeginStage ? "active" : "done"}
          content={!isBeginStage ? <OkIcon /> : "1"}
          isActiveDivider={!isBeginStage}
        />
        <Step
          state={secondStepState}
          content={isFinishStage ? <OkIcon /> : "2"}
          isActiveDivider={isFinishStage}
        />
        <Step state={isFinishStage ? "active" : "waiting"} content="3" isLast />
      </Steps>
    </Header>
  );
};

export default ConstructorHeader;
