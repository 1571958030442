import { useDelete } from "@pankod/refine-core";
import { IMember } from "interfaces";
import { useCallback } from "react";
import KickButtonComponent from "./kick-button-component";

interface KickButtonProps {
  communitySourceId: string;
  type: string;
  userId: string;
  memberName: string;
}

const KickButton: React.FC<KickButtonProps> = ({
  communitySourceId,
  type,
  userId,
  memberName,
}) => {
  const {mutate: kickUser} = useDelete<IMember>();

  const handleKick = useCallback(() => {
    kickUser({
      resource: "members",
      id: userId,
      dataProviderName: "custom_rest",
      values: {
        community_id: communitySourceId,
        user_id: userId,
        type
      }
    });
  }, [communitySourceId, userId, type])

  return (
    <KickButtonComponent 
      buttonText="Kick from server"
      action={handleKick}
      memberName={memberName}
    />
  );
};

export default KickButton;
