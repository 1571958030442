import {
  EmailContainer,
  EmailIcon,
  MemberAvatar,
  MemberItem,
  MemberName,
} from "./styled";
import { AntdList } from "@pankod/refine-antd";
import KickButton from "./kick-actions/kick-button";
import { useMemo } from "react";
import ServerKickButton from "./kick-actions/server-kick-button";
import { MemberCardActionType } from "./types";
import ApproveButton from "./approve-actions";
import RejectButton from "./reject-action";

type Item = {
  id: string;
  title: string;
  image: string;
  email?: string;
}

interface MemberCardProps {
  item: Item;
  communitySourceId?: string;
  actionTypes: MemberCardActionType[];
  type: string;
}

const MemberCard: React.FC<MemberCardProps> = ({
  item,
  actionTypes,
  communitySourceId = "",
  type
}) => {
  const actions = useMemo(() => {
    return actionTypes
      .map(it => {
        switch(it) {
          case "kick":
            return (
              <KickButton
                memberName={item.title} 
                communitySourceId={communitySourceId} 
                type={type} 
                userId={item.id} />
            )
          case "server-kick":
            return (
              <ServerKickButton 
                memberName={item.title}
                communitySourceId={communitySourceId}
                type={type}
                userId={item.id}
              />
            )
          case "approve":
            return (
              <ApproveButton
                requestId={item.id}
                memberName={item.title}
                memberEmail={item.email}
                communitySourceId={communitySourceId}
              />
            )
          case "reject":
            return (
              <RejectButton requestId={item.id} memberName={item.title} />
            )
        }
      })
      .filter(Boolean);
  }, [actionTypes, communitySourceId, type, item]);

  return (
    <MemberItem
      actions={actions}
    >
      <AntdList.Item.Meta
        style={item.email ? { alignItems: "center" } : { alignItems: "inherit" }}
        avatar={<MemberAvatar src={item.image} />}
        title={<MemberName>{item.title}</MemberName>}
        description={
          item.email ? (
            <EmailContainer>
              <EmailIcon />
              {item.email}
            </EmailContainer>
          ) : null
        }
      />
    </MemberItem>
  )
};

export default MemberCard;
