import { ReactNode } from "react";
import { Label } from "./styled";
import {
	SettingsItemContainer,
	SettingsItemTitle 
} from "../pages/settings/styled";

type SettingsItemProps = {
  icon: ReactNode;
	title: string;
  button: ReactNode;
};

const SettingsItem: React.FC<SettingsItemProps> = ({icon, title, button}) => {
  return (
		<SettingsItemContainer>
			<SettingsItemTitle>
				{icon}
				<Label>
					{title}
				</Label>
			</SettingsItemTitle>
			{button}
		</SettingsItemContainer>
	)
};

export default SettingsItem

