import styled from "styled-components";
import { AntdLayout } from "@pankod/refine-antd";
import { H2Text } from "../../components/default.styled";
import { DARK_BORDER_COLOR, DARK_COLOR, LIGHT_TEXT_COLOR } from "../../constants/style";

export const Layout = styled(AntdLayout)`
  height: 100vh;
`;

export const Content = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${DARK_COLOR};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  position: absolute;
  height: 90px;
  width: 100%;
  background: none;
`;

export const Header = styled.div` 
  height: 0px;
  width: 100%;
  padding: 0;
  margin:0;

  overflow: hidden;
  
  display: flex;
  flex-direction: raw;
  align-items: center;
  justify-content: space-between;

  transition: all .2s;

  background-color: ${DARK_COLOR};
  border-bottom: 1px solid ${DARK_BORDER_COLOR};

  ${HeaderContainer}:hover & {
    height: 90px;
    padding: 20px 40px;
  }
`;

export const Title = styled(H2Text)`
  color: ${LIGHT_TEXT_COLOR};
`;

export const ControllsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PreviewItem = styled.iframe<{$isFullSize?: boolean}>`
  ${props => props.$isFullSize ? `
  height: 100%;
  width: 100%;
  ` : `
    width: 600px;
    height: 400px;
  `}
`;