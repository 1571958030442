import { HttpError } from "@pankod/refine-core";
import axios from "@pankod/refine-simple-rest/node_modules/axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((req) => {
  req.withCredentials = true;

  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export default axiosInstance;
