import styled from "styled-components";
import {
  Typography,
  Button,
  AntdLayout,
  Upload,
  Select,
  Checkbox,
} from "@pankod/refine-antd";
import {
  imgIcon,
  okIcon,
  uploadIcon,
  trashIcon,
  plusIcon,
  editIcon,
  copyIcon,
  cocIcon,
  arrowIcon,
  successIcon,
} from "../../public/icons";
import {
  BtnWithoutBG,
  CustomInput,
  CustomTextarea,
  DarkBtn,
  P2Text,
} from "../../components/default.styled";
import {
  DefaultIcon,
  SimpleContainer,
  SimpleText,
} from "../../components/styled";
import {
  GREEN_COLOR,
  LIGHT_TEXT_COLOR,
  DARK_TEXT_COLOR,
  DARK_GREEN_COLOR,
  GRAY_COLOR,
  INPUT_PLACEHOLDER_COLOR,
  INPUT_BG_CLR,
  ORANGE,
  INPUT_BG_HOV_CLR,
  INPUT_DSBL_CLR,
  DARK_COLOR,
  GRN_BTN_BG_HOV,
} from "../../constants/style";

import type { StepState } from "./types";
import {
  WHITE_FILTER,
  GRAY_FILTER,
  GREEN_FILTER,
} from "../../constants/filters";

export const Wrapper = styled(AntdLayout.Content)<{ $centered?: boolean }>`
  padding: 0 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => (props.$centered ? "justify-content: center;" : "")}
  flex-grow: 1;
  overflow-y: auto;
`;

export const ConstructorHeader = styled(AntdLayout.Header)`
  background-color: initial;
  padding: 40px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
`;

export const Steps = styled.div`
  width: 100%;
  display: flex;
  grid-column: 2 / 3;
  justify-content: space-between;
  align-items: center;
`;

export const Step = styled.div<{ state: StepState }>`
  height: 44px;
  width: 44px;

  ${(props) =>
    props.state === "active"
      ? `
    background-color: ${GREEN_COLOR};
    box-shadow: 0px 5px 20px rgba(198, 249, 90, 0.3);
    color: ${GRAY_COLOR};
  `
      : null}

  ${(props) =>
    props.state === "waiting"
      ? `
    background-color: ${GRAY_COLOR};
    color: ${DARK_TEXT_COLOR};
  `
      : null}
   
  ${(props) =>
    props.state === "done" ? `background-color: ${DARK_GREEN_COLOR};` : null}
  
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
`;

export const Divider = styled.div<{ isActive?: boolean }>`
  height: 4px;
  flex-grow: 1;
  background-color: ${(props) =>
    props.isActive ? DARK_GREEN_COLOR : GRAY_COLOR};
`;

export const OkIcon = styled.img.attrs({
  src: okIcon,
})`
  height: 30px;
  width: 30px;
  ${GREEN_FILTER}
`;

export const Title = styled(Typography.Title)`
  font-size: 32px !important;

  &.ant-typography {
    color: ${LIGHT_TEXT_COLOR};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px;

  width: 290px;
  padding: 20px;
  background-color: ${GRAY_COLOR};
  border-radius: 20px;

  cursor: pointer;
`;

export const CardTitle = styled(Title)`
  margin-top: 10px;
`;

export const CardIconContainer = styled.div`
  padding: 10px;
`;

export const CardIcon = styled.img`
  ${GREEN_FILTER}
  height: 60px;
  width: 60px;
`;

export const FinishImageContainer = styled.div`
  margin: 30px;
`;

export const StageContent = styled.div`
  width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin: 10px 0;

  & .ant-input-group > input {
    width: calc(100% - 42px);
  }

  & .ant-input-group > textarea {
    width: calc(100% - 42px);
  }
`;

export const CopyButton = styled(Button)<{ isTextarea?: boolean }>`
  height: 100%;
  width: 42px;
  background: ${GRAY_COLOR};
  border: none;

  display: flex;
  align-items: flex-start;

  &:hover {
    background: ${GRAY_COLOR};
  }

  & > img {
    ${(props) => (props.isTextarea ? "position: relative; top: -33px;" : null)}
    ${GRAY_FILTER}
  }

  &:hover img {
    ${GREEN_FILTER}
  }

  &:focus {
    background: ${GRAY_COLOR};
  }

  &:focus img {
    ${GREEN_FILTER}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  margin-top: 40px;
`;

export const UploadControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UploadButton = styled(DarkBtn)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: inherit;
`;

export const ImageIcon = styled(DefaultIcon).attrs({ src: imgIcon })`
  margin-right: 10px;
`;

export const UploadIcon = styled(DefaultIcon).attrs({ src: uploadIcon })`
  margin-left: 10px;
  margin-bottom: 3px;

  height: 23px;
  width: 23px;
  ${WHITE_FILTER}

  ${UploadButton}:hover & {
    ${GREEN_FILTER}
  }
`;

export const UploadSubtitle = styled(P2Text)<{ $isPreview?: boolean }>`
  ${(props) => (props.$isPreview ? "" : "text-align: center;")}
  color: ${INPUT_PLACEHOLDER_COLOR};

  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UploadTitleContainer = styled.div`
  display: flex;
`;

export const UploadTitle = styled(P2Text)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: ${LIGHT_TEXT_COLOR};
`;

export const UploadTitleGreen = styled(UploadTitle)`
  color: ${GREEN_COLOR};
`;

export const Dragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background: none;
    width: 100%;
    height: 200px;
    border: 1px dashed ${INPUT_PLACEHOLDER_COLOR};
    border-radius: 10px;
  }

  & .ant-upload.ant-upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const PreviewContainer = styled.div`
  background-color: ${GRAY_COLOR};
  border-radius: 10px;

  margin-top: 10px;

  align-items: center;

  display: flex;
  padding: 10px;

  padding-right: 20px;
`;

export const PreviewImage = styled.img`
  height: 60px;
  width: 60px;

  border-radius: 5px;
`;

export const PreviewInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  margin: 0 20px;
`;

export const CocIcon = styled(DefaultIcon).attrs({ src: cocIcon })`
  height: 24px;
  width: 24px;
  margin-right: 13px;
`;

export const PreviewDeleteIcon = styled(DefaultIcon).attrs({ src: trashIcon })`
  ${GRAY_FILTER}
  cursor: pointer;
  height: 25px;
  width: 25px;
  &:hover {
    ${GREEN_FILTER}
  }
`;
export const EditIcon = styled(DefaultIcon).attrs({ src: editIcon })`
  ${GRAY_FILTER}
  cursor: pointer;
  margin-right: 20px;
  height: 24px;
  width: 24px;
  &:hover {
    ${GREEN_FILTER}
  }
`;
export const CopyIcon = styled(DefaultIcon).attrs({ src: copyIcon })`
  ${GRAY_FILTER}
  cursor: pointer;
  margin-right: 20px;
  height: 24px;
  width: 24px;
  &:hover {
    ${GREEN_FILTER}
  }
`;

export const AddButton = styled(BtnWithoutBG)`
  align-self: start;
  ont-weight: 400;
  font-size: 16px;
  color: ${GREEN_COLOR};
`;

export const AddIcon = styled(DefaultIcon).attrs({ src: plusIcon })`
  margin-right: 13px;
  width: 18px;
  height: 18px;
  transition: inherit;
  ${GREEN_FILTER}
  ${AddButton}:hover & {
    ${WHITE_FILTER}
  }
`;
export const CocContainer = styled(SimpleContainer)`
  padding: 0;
  width: 100%;
`;

export const CocText = styled(SimpleText)`
  color: ${LIGHT_TEXT_COLOR};
`;
export const TextArea = styled(CustomTextarea)`
  &::-webkit-scrollbar {
    background: ${GRAY_COLOR}; /* color of the tracking area */
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${GREEN_COLOR};
    border-radius: 10px;

    border: 4px ${GRAY_COLOR} solid;
  }
`;
export const ConstructorTextarea = styled(CustomTextarea)`
  &.ant-input-status-error:not(.ant-input-disabled):not(
      &.ant-input-borderless
    ).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(
      &.ant-input-borderless
    ).ant-input:hover {
    background: ${INPUT_BG_CLR};
    border: 1px solid ${ORANGE};
  }
`;

export const ConstructorInput = styled(CustomInput)`
  height: 42px;
  &.ant-input-status-error:not(.ant-input-disabled):not(
      &.ant-input-borderless
    ).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(
      &.ant-input-borderless
    ).ant-input:hover {
    background: ${INPUT_BG_CLR};
    border: 1px solid ${ORANGE};
  }
`;

export const ConstructorSelect = styled(Select)`
  background: ${INPUT_BG_CLR};
  padding: 10px 15px;
  border: none;
  color: ${LIGHT_TEXT_COLOR};
  border-radius: 10px;
  height: 42px;
  display: flex;
  align-items: center;
  &::placeholder {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }
  &:hover {
    background: ${INPUT_BG_HOV_CLR};
  }
  &:disable {
    color: ${INPUT_DSBL_CLR};
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const ArrowIcon = styled(DefaultIcon).attrs({ src: arrowIcon })`
  margin-left: 10px;
  margin-bottom: 3px;
  border: none;
  height: 23px;
  width: 23px;
  ${WHITE_FILTER}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ConstructorCheckbox = styled(Checkbox)`
  & .ant-checkbox-input {
    margin: 0;
  }

  & .ant-checkbox .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    background-color: ${DARK_COLOR};
    border-color: ${DARK_TEXT_COLOR};
  }

  & .ant-checkbox-checked::after {
    border: none;
  }

  & .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${GREEN_COLOR};
    border-color: ${GREEN_COLOR};
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      background-color: ${GRN_BTN_BG_HOV};
      border-color: ${GRN_BTN_BG_HOV};
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 1px solid ${DARK_COLOR};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1.6) translate(-33%,-48%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover 
    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      background-color: ${DARK_COLOR};
      border-color: ${DARK_TEXT_COLOR};
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner::after {
      border-color: ${GREEN_COLOR};
  }

  & .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1.6) translate(-33%, -48%) !important;
    border: 1px solid ${DARK_COLOR};
    border-top: 0;
    border-left: 0;
  }
`;
