import styled from "styled-components";
import { Layout } from "antd";
import {
  DARK_COLOR,
  DARK_TEXT_COLOR,
  LIGHT_TEXT_COLOR,
} from "../../constants/style";
import { H1Text, P1Text, GrnBtn } from "../default.styled";

export const Wrapper = styled(Layout)`
  height: 100vh;
  width: 100%;
`;

export const NotFoundContent = styled(Layout.Content)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${DARK_COLOR};
`;

export const NotFoundTitle = styled(H1Text)<{ $fontSize?: string }>`
  text-align: center;
  color: ${LIGHT_TEXT_COLOR};
  margin: ${(props) => (props.$fontSize ? "10px 0" : "30px 0 20px")};
  ${(props) => (props.$fontSize ? "font-size:" + props.$fontSize + ";" : "")}
`;

export const SimpleText = styled(P1Text)`
  color: ${DARK_TEXT_COLOR};
  text-align: center;
  margin: 0;
`;

export const GreenButton = styled(GrnBtn)<{ $marginTop?: string }>`
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : "50px")};
`;
